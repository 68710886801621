import { formatDistance, fromUnixTime, addMilliseconds, parse, format, parseJSON, differenceInCalendarDays } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { da } from 'date-fns/locale';
import { singleton } from 'tsyringe';

@singleton()
export class FormatService {
    private dateFormatter = new Intl.DateTimeFormat(undefined, {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
    });
    private monthFormatter = new Intl.DateTimeFormat(undefined, {
        month: 'short',
        year: 'numeric',
    });
    private timeFormatter = new Intl.DateTimeFormat(undefined, {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
    });
    private hourFormatter = new Intl.DateTimeFormat(undefined, {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
    });
    private datetimeFormatter = new Intl.DateTimeFormat(undefined, {
        hour: 'numeric',
        minute: 'numeric',
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
    });
    private datetimenosecFormatter = new Intl.DateTimeFormat(undefined, {
        hour: 'numeric',
        minute: 'numeric',
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
    });
    private dateShortFormatter = new Intl.DateTimeFormat(undefined, {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
    });
    private moneyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });
    private dateSpelledOutFormatter = new Intl.DateTimeFormat(undefined, {
        year: 'numeric',
        month: 'long',
        day: '2-digit',
    });

    private moneyFormatterNoDecimals = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
    });
    private moneyFormatter4Decimals = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 4,
        minimumFractionDigits: 4,
    });
    private moneyFormatterAllDecimals = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 17,
        minimumFractionDigits: 2,
    });

    private numberFormatter = new Intl.NumberFormat();
    private intFormatter = new Intl.NumberFormat(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });
    private decimalFormatter1Dec = new Intl.NumberFormat(undefined, {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
    });
    private decimalFormatter2Dec = new Intl.NumberFormat(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
    private decimalFormatter3Dec = new Intl.NumberFormat(undefined, {
        minimumFractionDigits: 3,
        maximumFractionDigits: 3,
    });
    private decimalFormatter4Dec = new Intl.NumberFormat(undefined, {
        minimumFractionDigits: 4,
        maximumFractionDigits: 4,
    });

    public formatMoney(price: number): string {
        return this.moneyFormatter.format(price);
    }

    /**
     * Format money with two decimals truncated (e.g., $1.43), including non-zero indicator (e.g., < $0.01)
     * @param price
     */
    public formatMoneyNonZeroTwoDecimals(price: number): string {
        if (price > 0 && price < 0.01) return '< $0.01';
        if (price < 0 && price > -0.01) return '> -$0.01';
        else return this.formatMoney(price);
    }

    /**
     * Format money with 0, 2 decimals according to a the price or additionally, a range of other sample values.
     * Sample output: '$1.93', '> -$0.01', '$12,553', '$5.00', '$10', '< $0.01', '$0', '$0.0017'
     * @param price
     */
    public formatMoneySignificantDecimals(price: number, ...range: number[]): string {
        if (price === 0) return this.formatMoneyNoDecimals(price);

        if (price > 0 && price < 0.01) return '< $0.01';
        if (price < 0 && price > -0.01) return '> -$0.01';

        const max = Math.max(...[price, ...range].map((n) => Math.abs(n)));
        if (max > 10) return this.formatMoneyNoDecimals(price);

        return this.formatMoney(price);
    }
    /**
     * Multiply by 100, round, and add commas, e.g.,
     * 1.7 -> 170%
     * 12 -> 1,200%
     * 0.01 -> 1%
     */
    public formatPercent(price: number, max?: number): string {
        max ??= Infinity;
        const absPrice = Math.abs(price);
        return price === 0
            ? '0%'
            : price < 0 && absPrice < 0.01
            ? '< -1%'
            : absPrice < 0.01
            ? '> 0%'
            : absPrice * 100 > max && price < 0
            ? '< ' + this.formatInt0Dec(max) + '%'
            : price > max
            ? '> ' + this.formatInt0Dec(max) + '%'
            : this.formatInt0Dec(Math.round(price * 100)) + '%';
    }

    public formatPercentRoundDown(price: number): string {
        if (price < 1 && price > 0.99) {
            return '99%';
        }
        return Math.round(price * 100) + '%';
    }

    /**
     * Format money with decimals truncated, e.g, $1.7 -> $1, $1.01 -> $1
     * @param price
     * @returns
     */
    public formatMoneyTruncateDecimals(price: number): string {
        return typeof price === 'number' ? this.moneyFormatterNoDecimals.format(Math.trunc(price)) : '—';
    }

    /**
     * Format money rounded to nearest dollar, $0
     * @param price
     * @returns
     */
    public formatMoneyNoDecimals(price: number): string {
        return typeof price === 'number' ? this.moneyFormatterNoDecimals.format(price) : '—';
    }

    /**
     * Format money with 4 decimals, $0.0000
     * @param price
     * @returns
     */
    public formatMoney4Decimals(price: number): string {
        return typeof price === 'number' ? this.moneyFormatter4Decimals.format(price) : '—';
    }

    public formatMoneyAllDecimals(price: number): string {
        return typeof price === 'number' ? this.moneyFormatterAllDecimals.format(price) : '—';
    }

    public toUtc(date: Date) {
        return date.getTimezoneOffset() === 0 ? date : zonedTimeToUtc(date, this.getTzName());
    }
    public normalizeToLocal(date: Date) {
        return date.getTimezoneOffset() === 0 ? utcToZonedTime(date, this.getTzName()) : date;
    }

    /**
     * Convert a json date without time to a local date
     * e.g. 2021-01-01 -> 2021-01-01T00:00:00
     * e.g. 1703980800000 -> 2023-12-31T00:00:00
     * @param jsonDate
     * @returns
     */
    public parseDateNoTime(jsonDate: string | number | Date) {
        return new Date(this.formatAsLocal(jsonDate));
    }
    /**
     * Format date as timezone neutral, e.g. 2021-01-01T00:00:00 (no Z or offset at the end)
     * @param jsonDate
     * @returns
     */
    public formatAsLocal(jsonDate: string | number | Date) {
        if (jsonDate instanceof Date) {
            jsonDate = jsonDate.toISOString();
        }
        if (typeof jsonDate === 'number') {
            jsonDate = new Date(jsonDate).toISOString();
        }
        jsonDate = jsonDate.length === 7 ? `${jsonDate}-01` : jsonDate;
        return jsonDate.replace('Z', '') + (jsonDate.includes('T') ? '' : 'T00:00:00');
    }

    /**
     * Format date locale sensitive, include time if date is today, e.g. 11/21/2001, 1:00 PM
     * @param dateValue
     * @returns
     */
    public formatDateWithTodaysTime(dateValue: string | Date) {
        const date = typeof dateValue === 'string' ? this.toLocalDate(dateValue) : dateValue;
        return !date ? '' : differenceInCalendarDays(new Date(), date) > 1 ? this.formatDate(date) : this.formatDatetime(date);
    }

    /**
     * Given a json date from the server, convert to local date
     * @param date
     * @returns
     */
    public toLocal(date: number | string | undefined | null) {
        const dateZ = this.toLocalDate(date);
        return this.formatDatetime(dateZ);
    }
    /**
     * Given a json date from the server, convert to local date without secs
     * @param date
     * @returns
     */
    public toLocalNoSeconds(date: number | string | undefined | null) {
        const dateZ = this.toLocalDate(date);
        return this.formatDatetimeNoSecs(dateZ);
    }

    public toLocalLongFormat(date: number | string | undefined | null) {
        const dateZ = this.toLocalDate(date);
        return this.toMonthLongForm(dateZ);
    }
    /**
     * Given a json date from the server, convert to local date string
     * @param date
     * @returns
     */
    public toLocalDate(date: number | string | undefined | null | Date) {
        date = date ?? '';
        if (typeof date === 'number') {
            date = addMilliseconds(0, date);
        }
        return date instanceof Date
            ? this.normalizeToLocal(date)
            : date.match(/z$/gi)
            ? new Date(date)
            : date.match(/^\d{4}-\d{2}-\d{2}$/)
            ? new Date(date + 'T00:00')
            : parseJSON(date);
    }

    /**
     * Format date as yyyy-MM-dd, e.g. 2021-01-01
     * @param date
     * @returns
     */
    public toJsonShortDate(date: Date) {
        return format(date, 'yyyy-MM-dd');
    }
    /**
     * Format date as UTC yyyy-MM-dd, e.g. 2021-01-01 or 2021-01-01T00:00:00Z
     * @param date
     * @returns
     */
    public toUtcJsonShortDate(date: Date, includeTime: boolean = false) {
        const result = JSON.stringify(date).substring(1, 11);
        return !includeTime ? result : result + 'T00:00:00Z';
    }
    /**
     * Format date as yyyy-MM, e.g. 2021-01
     * @param date
     * @returns
     */
    public formatYearMonth(date: Date): any {
        return format(date, 'yyyy-MM');
    }
    /**
     * Format date as LLL do, e.g. Jan 1st
     * @param date
     * @returns
     */
    public formatMonthDay(date: Date): any {
        return format(date, 'LLL do');
    }
    /**
     * Format date as LLLL yyyy, e.g. January 2021
     * @param date
     * @returns
     */
    public formatLongMonthYear(date: Date) {
        return format(date, 'LLLL yyyy');
    }
    /**
     * Format date as LLL yyyy, e.g. Jan 2021
     * @param date
     * @returns
     */
    public formatShortMonthYear(date: Date) {
        return format(date, 'LLL yyyy');
    }
    public to8DigitDate(date: Date) {
        return format(date, 'yyyyMMdd');
    }
    public from8DigitDate(date: string) {
        return parse(date, 'yyyyMMdd', 0);
    }
    /**
     * Parse date from yyyy-MM, e.g. 2021-01
     * @param date
     * @returns
     */
    public fromMonthYear(date: string) {
        return parse(date, 'yyyy-MM', 0);
    }
    /**
     * Format date as EEE do, e.g. Mon 1st
     * @param date
     * @returns
     */
    public formatLongDay(date: Date) {
        return format(date, 'EEE do');
    }
    /**
     * Locale sensitive short date format, e.g. Jan 1, 2021
     * Optionally include day of week, e.g. Mon Jan 1, 2021
     * @param date
     * @param includeDow
     * @returns
     */
    public toShortDate(date: Date, includeDow: boolean = false) {
        return isNaN(date as any)
            ? ''
            : includeDow
            ? `${format(date, 'EEE')} ` + this.dateShortFormatter.format(date)
            : this.dateShortFormatter.format(date);
    }
    /**
     * Format date as M/d do, e.g. 6/01
     * @param date
     * @returns
     */
    public formatShortDate(date: Date) {
        return format(date, 'M/d');
    }
    /**
     * Locale sensitive number date format, 10/1/2021
     * @param date
     * @returns
     */
    public formatDate(date: Date) {
        return isNaN(date as any) ? '' : this.dateFormatter.format(date);
    }
    public formatWeek(date: Date) {
        return isNaN(date as any) ? '' : this.dateFormatter.format(date);
    }
    /**
     * Locale sensitive short month format, e.g. Jan 2021
     * @param date
     * @returns
     */
    public formatMonth(date: Date) {
        return isNaN(date as any) ? '' : this.monthFormatter.format(date);
    }
    public formatDatetime(date: Date) {
        return isNaN(date as any) ? '' : this.datetimeFormatter.format(date);
    }
    /**
     * Locale sensitive time format, e.g., 5:23:45 PM
     * @param date
     * @returns
     */
    public formatTime(date: Date) {
        return isNaN(date as any) ? '' : this.timeFormatter.format(date);
    }
    /**
     * Locale sensitive hour format, e.g., 5 PM
     * @param date
     * @returns
     */
    public formatHour(date: Date) {
        return isNaN(date as any) ? '' : this.hourFormatter.format(date);
    }

    public formatDatetimeNoSecs(date: Date) {
        return isNaN(date as any) ? '' : this.datetimenosecFormatter.format(date);
    }
    public toMonthLongForm(date: Date) {
        return isNaN(date as any) ? '' : this.dateSpelledOutFormatter.format(date);
    }
    public formatInt(value: number) {
        return this.numberFormatter.format(value);
    }

    public formatInt0Dec(value: number) {
        return this.intFormatter.format(value);
    }
    public formatDecimal2(value: number) {
        return this.decimalFormatter2Dec.format(value);
    }
    public formatDecimal(value: number, decimals: 1 | 2 | 3 | 4) {
        return decimals === 1
            ? this.decimalFormatter1Dec.format(value)
            : decimals === 2
            ? this.decimalFormatter2Dec.format(value)
            : decimals === 3
            ? this.decimalFormatter3Dec.format(value)
            : decimals === 4
            ? this.decimalFormatter4Dec.format(value)
            : '';
    }

    public getTzName() {
        return Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
    public getTzOffsetHours() {
        return new Date().getTimezoneOffset() / 60;
    }

    public userFriendlyCamelCase(text: string) {
        return text
            .replace(/([a-z])([A-Z])/g, '$1 $2')
            .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
            .replace(/^[a-z]/, (v) => v.toLocaleUpperCase());
    }

    /**
     * User-friendly time since a date, e.g., 5 minutes ago
     * @param date
     * @returns
     */
    public timeAgo(date: Date) {
        return this.timeElapsed(date) + ' ago';
    }

    /**
     * User-friendly duration between two dates, e.g., 5 minutes
     * @param date
     * @returns
     */
    public timeElapsed(from: Date, to: Date = new Date()) {
        if (from.getTimezoneOffset() === 0) {
            to = this.toUtc(to);
        }
        return formatDistance(from, to);
    }

    public awsIdToName(id: string) {
        return id.replace(/\d\.([a-z]+-[a-z]+-\d\.)?/, '');
    }

    public awsAccountId(id: string) {
        return id.indexOf('-') >= 0 ? id : id.replace(/(\d{4})(\d{4})(\d{4})/, '$1-$2-$3');
    }

    public adjustCspName(platform: string) {
        return platform === 'Aws' ? 'AWS' : platform;
    }

    public formatBytes(bytes: number, decimals: number | null = 2) {
        if (!+bytes) return '0 Bytes';

        const k = 1024;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.max(0, Math.floor(Math.log(bytes) / Math.log(k)));

        const total = bytes / Math.pow(k, i);
        const dm = decimals !== null ? decimals : bytes < k ? 0 : total < 10 ? 1 : 0;

        return `${total.toFixed(dm)} ${sizes[i]}`;
    }
}
