import { QueryExpr, QueryOperation } from '@apis/Resources';
import { QuerySelectExpr } from '@apis/Resources/model';
import { ActionIcon, Box, Button, Divider, Group, NumberInput, Popover, SegmentedControl, Select, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { ChartMargin } from '@root/Components/Charts/Common';
import { FilterItem, FilterToken } from '@root/Components/Filter/Design';
import { AddFilterButton, DataFilterModel, DataFilters, FilterExpr } from '@root/Components/Filter/Filters';
import { operationLookup, QueryDescriptorService } from '@root/Components/Filter/Services';
import { FieldPicker } from '@root/Components/Picker/FieldPicker';
import { useEvent } from '@root/Services/EventEmitter';
import { FieldInfo, traverseExpr } from '@root/Services/QueryExpr';
import { observer } from 'mobx-react';
import { ForwardedRef, forwardRef, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { AnonymousDashboardItemConfig } from '../Models';
import { SettingsDivider, SettingsLabel } from './Design';
import { ChartEditor } from './Models';

function getTokens(queryDescriptorSvc: QueryDescriptorService, expr: QueryExpr) {
    if ('Operation' in expr && traverseExpr(expr, (x) => 'Operation' in x && x.Operation === 'and')) {
        expr = { Operation: expr.Operation, Operands: [] };
    }
    return queryDescriptorSvc.getTokens(expr);
}

interface EditorSelectExprProps {
    editor: ChartEditor;
    expr?: QuerySelectExpr;
    onChange: (expr: QuerySelectExpr) => void;
    onRemove?: () => void;
    operations?: string[];
    types?: string[];
    opener?: (open: () => void) => ReactNode;
}
export function EditorSelectExpr({ editor, expr, onChange, types, onRemove, opener }: EditorSelectExprProps) {
    const queryExpr = expr?.Expr;
    const isAgg = traverseExpr((queryExpr as QueryExpr) ?? { Value: '' }, (x) => 'Operation' in x && operationLookup.get(x.Operation)?.aggregate);
    const Component = isAgg ? AggregateOperation : EditorField;
    const setQueryExpr = useCallback(
        (expr: QueryExpr) => {
            const tokens = queryExpr ? getTokens(editor.queryDescriptorSvc, expr).map((t) => t.text) : ['None'];
            onChange({ Alias: tokens.join(' '), Expr: { ...expr } });
        },
        [expr, onChange]
    );
    return <Component editor={editor} types={types} onChange={setQueryExpr} expr={queryExpr as QueryExpr} onRemove={onRemove} opener={opener} />;
}

export function FilterableAggregate({ editor, expr, onChange }: EditorSelectExprProps) {
    const operation = (select: QuerySelectExpr) =>
        traverseExpr((select.Expr as QueryExpr) ?? { Value: '' }, (x) => ('Operation' in x ? (x as QueryOperation) : undefined));
    const isAgg = (select: QuerySelectExpr) => operationLookup.get(operation(select)?.Operation?.toLowerCase() ?? '')?.aggregate;
    const operationName = (select: QuerySelectExpr) => operation(select)?.Operation?.toLowerCase();
    const canFilter = (select: QuerySelectExpr) => ['count', 'percent'].includes(operationName(select) ?? '');
    const agg = expr ? isAgg(expr) : false;
    const filterable = expr ? canFilter(expr) : false;
    const conditionOp = !expr
        ? undefined
        : traverseExpr(expr.Expr as QueryExpr, (x) => ('Operation' in x && x.Operation === 'and' ? (x as QueryOperation) : undefined));
    const conditions = conditionOp?.Operands;
    const applyFilters = (filters: QueryExpr[]) => {
        const op = expr ? operation(expr) : undefined;
        if (op) {
            if (!op.Operands) {
                op.Operands = [];
            }

            if (!filters.length) {
                op.Operands?.splice(0, Infinity);
            } else if (!op.Operands?.length) {
                op.Operands.push({ Operation: 'and', Operands: filters });
            } else {
                conditionOp!.Operands = filters;
            }
            onChange(expr!);
        }
    };

    return (
        <DataFilters
            align="end"
            valueProvider={editor.valueProvider}
            fieldInfoProvider={editor.queryDescriptorSvc.fieldInfoProvider}
            filters={(conditions ?? []) as QueryExpr[]}
            onChange={applyFilters}
            renderFieldPicker={(select) => (
                <Box>
                    <FieldPicker mode="single" height={300} selections={[]} schema={editor.shemaSvc} onChange={([f]) => select(f.path)} />
                </Box>
            )}
            renderAddFilter={(model) => (
                <>
                    <EditorSelectExpr
                        editor={editor}
                        onChange={onChange}
                        expr={expr}
                        opener={(open) => <ExprTag editor={editor} onClick={open} expr={expr?.Expr as QueryExpr} />}
                        types={agg ? ['number', 'date'] : undefined}
                    />
                    {filterable ? <AddFilterButton onClick={model.addEmptyFilter} /> : null}
                </>
            )}
        />
    );
}

export function FilterSettings({ editor }: { editor: ChartEditor }) {
    const conditions = editor.settings.filters;
    const [filterModel, setFilterModel] = useState<DataFilterModel>();
    const applyFilters = useCallback(
        (filters: QueryExpr[]) => {
            editor.settings.filters = filters?.length ? filters : undefined;
            editor.onSettingsChanged.emit();
        },
        [editor]
    );
    useEvent(editor.onSettingsChanged);

    return (
        <Box>
            <Group position="apart">
                <SettingsLabel>Filter</SettingsLabel>
                {filterModel && <AddFilterButton onClick={filterModel.addEmptyFilter} />}
            </Group>
            <DataFilters
                align="end"
                valueProvider={editor.valueProvider}
                fieldInfoProvider={editor.queryDescriptorSvc.fieldInfoProvider}
                filters={(conditions ?? []) as QueryExpr[]}
                onChange={applyFilters}
                onModelLoaded={setFilterModel}
                renderFieldPicker={(select) => (
                    <Box>
                        <FieldPicker mode="single" height={300} selections={[]} schema={editor.shemaSvc} onChange={([f]) => select(f.path)} />
                    </Box>
                )}
            />
        </Box>
    );
}

interface EditorFieldProps {
    editor: ChartEditor;
    expr?: QueryExpr;
    onChange: (expr: QueryExpr) => void;
    onRemove?: () => void;
    types?: string[];
    opener?: (open: () => void) => ReactNode;
}
export function EditorField({ editor, expr, onChange, types, onRemove, opener }: EditorFieldProps) {
    const fieldExpr = useMemo(() => (!expr ? null : editor.queryDescriptorSvc.search(expr, (x) => ('Field' in x ? x : undefined))), [expr]);
    const fieldInfo = useMemo(() => editor.shemaSvc.getField(fieldExpr?.Field ?? '', fieldExpr?.Type), [expr, fieldExpr, fieldExpr?.Field]);
    const [opened, { open, close }] = useDisclosure(false);
    const handleFieldChange = useCallback(
        (fields: FieldInfo[]) => {
            const field = fields[0];
            onChange({ Field: field.path, Type: field.rootType.type.TypeId ?? undefined });
            close();
        },
        [expr, onChange, close]
    );

    return (
        <Popover opened={opened} onClose={close} withinPortal shadow="lg" withArrow position="left">
            <Popover.Target>{opener ? opener(open) : <ExprTag expr={expr} onClick={open} onRemove={onRemove} editor={editor} />}</Popover.Target>
            <Popover.Dropdown p={0}>
                <Box sx={{ height: 400 }}>
                    <FieldPicker
                        mode="single"
                        schema={editor.shemaSvc}
                        types={types}
                        onChange={handleFieldChange}
                        selections={fieldInfo ? [fieldInfo] : []}
                    />
                </Box>
            </Popover.Dropdown>
        </Popover>
    );
}

export function AddAggregationOperation(props: EditorFieldProps) {
    const [expr, setExpr] = useState<QueryExpr>({ Operation: 'count', Operands: [] });
    const add = (close: () => void) => {
        props.onChange(expr);
        close();
    };

    return <AggregateOperation {...props} expr={expr} onChange={setExpr} doneText="Add" onDone={add} />;
}

const defaultAgregateOptions = ['count', 'countvalues', 'countuniquevalues', 'percent', 'sum', 'avg', 'min', 'max'] as string[];
interface AggregateOperationProps extends EditorFieldProps {
    onDone?: (close: () => void) => void;
    doneText?: string;
    options?: string[];
}
export function AggregateOperation({ options = defaultAgregateOptions, ...props }: AggregateOperationProps) {
    const { editor, expr, onChange, types, onRemove, opener, onDone, doneText } = props;
    const operation = expr as QueryOperation;
    const operationInfo = editor.queryDescriptorSvc.getOperationInfo(operation);
    const fieldExpr = useMemo(() => (!expr ? null : editor.queryDescriptorSvc.search(expr, (x) => ('Field' in x ? x : undefined))), [expr]);
    const fieldInfo = useMemo(() => editor.shemaSvc.getField(fieldExpr?.Field ?? '', fieldExpr?.Type), [expr, fieldExpr, fieldExpr?.Field]);
    const [opened, { open, close }] = useDisclosure(false);
    const handleFieldChange = useCallback(
        (fields: FieldInfo[]) => {
            const field = fields[0];
            onChange({ Operation: operation.Operation, Operands: [{ Field: field.path, Type: field.rootType.type.TypeId ?? undefined }] });
        },
        [onChange, close, operation]
    );
    const handleOperationChange = useCallback(
        (operation: string) => {
            const operationInfo = editor.queryDescriptorSvc.getOperationInfo(operation);
            if (operationInfo) {
                onChange({ Operation: operation, Operands: [] });
            }
        },
        [fieldExpr, onChange]
    );
    const showFieldPicker = operationInfo?.operation !== 'percent' && operationInfo?.operation !== 'count';
    const opParam = operationInfo?.params?.[0];
    const validTypes = !opParam ? types : opParam.type ? [opParam.type] : undefined;
    useEvent(editor.onSettingsChanged);

    return (
        <Popover opened={opened} onClose={close} withinPortal shadow="lg" position="left" withArrow>
            <Popover.Target>{opener ? opener(open) : <ExprTag expr={expr} onClick={open} onRemove={onRemove} editor={editor} />}</Popover.Target>
            <Popover.Dropdown p={0}>
                <Box sx={{ width: 600 }}>
                    <Group position="apart" p="md" noWrap>
                        <SettingsLabel>Operation</SettingsLabel>
                        <SegmentedControl
                            size="xs"
                            color="primary"
                            data={options.map((o) => ({ value: o, label: editor.queryDescriptorSvc.getOperationInfo(o)!.name ?? o }))}
                            onChange={handleOperationChange}
                            value={operationInfo?.operation ?? ''}
                        />
                    </Group>
                    {showFieldPicker ? (
                        <Box sx={{ height: 350 }}>
                            <Divider />
                            <FieldPicker
                                minimizeHeight={false}
                                mode="single"
                                schema={editor.shemaSvc}
                                types={validTypes}
                                onChange={handleFieldChange}
                                width="auto"
                                selections={fieldInfo ? [fieldInfo] : []}
                            />
                        </Box>
                    ) : null}
                    <Divider />
                    <Box sx={{ textAlign: 'right' }} p="md">
                        <Button onClick={() => (onDone ? onDone(close) : close())}>{doneText ?? 'Done'}</Button>
                    </Box>
                </Box>
            </Popover.Dropdown>
        </Popover>
    );
}

interface ExprTagProps {
    expr?: QueryExpr;
    onClick: () => void;
    onRemove?: () => void;
    editor: ChartEditor;
}
export const ExprTag = forwardRef<HTMLDivElement, ExprTagProps>(function ExprTag(
    { expr, onClick, onRemove, editor }: ExprTagProps,
    ref: ForwardedRef<HTMLDivElement>
) {
    const tokens = expr ? getTokens(editor.queryDescriptorSvc, expr) : [{ name: 'None', type: 'none' }];
    return (
        <Group spacing={0} ref={ref}>
            <FilterItem state="valid" onClick={onClick} tabIndex={0} role="button">
                {tokens.map((t, i) => (
                    <Tooltip label={t.name}>
                        <FilterToken maxWidth={180} key={i}>
                            {t.name}
                        </FilterToken>
                    </Tooltip>
                ))}
            </FilterItem>
            {expr && onRemove ? (
                <ActionIcon onClick={onRemove}>
                    <i className="ti ti-trash"></i>
                </ActionIcon>
            ) : null}
        </Group>
    );
});

export const ChartMargins = observer(function ChartMargins({ settings: { margin } }: { settings: { margin?: ChartMargin } }) {
    return (
        <>
            <SettingsDivider />
            <SettingsLabel icon="ti ti-box-margin">Margins</SettingsLabel>
            <Group position="center">
                <NumberInput
                    label="Top"
                    size="xs"
                    sx={{ width: '6rem' }}
                    value={margin!.top}
                    onChange={(value) => (margin!.top = value || 0)}
                    step={5}
                />
            </Group>
            <Group position="apart">
                <NumberInput
                    label="Left"
                    size="xs"
                    sx={{ width: '6rem' }}
                    value={margin!.left}
                    onChange={(value) => (margin!.left = value || 0)}
                    step={5}
                />
                <NumberInput
                    label="Right"
                    size="xs"
                    sx={{ width: '6rem' }}
                    value={margin!.right}
                    onChange={(value) => (margin!.right = value || 0)}
                    step={5}
                />
            </Group>
            <Group position="center">
                <NumberInput
                    label="Bottom"
                    size="xs"
                    sx={{ width: '6rem' }}
                    value={margin!.bottom}
                    onChange={(value) => (margin!.bottom = value || 0)}
                    step={5}
                />
            </Group>
        </>
    );
});

export const NumberSettings = observer(function NumberSettings({
    value,
    decimals,
    onChange,
    label,
    min,
}: {
    value: number | undefined | null;
    decimals?: number;
    onChange: (value: null | number | undefined) => void;
    label: string;
    min?: number;
}) {
    return (
        <Group position="apart">
            <SettingsLabel>{label}</SettingsLabel>
            <NumberInput
                size="xs"
                sx={{ width: 60 }}
                min={min}
                value={value === null ? undefined : value}
                precision={decimals ?? 0}
                onChange={onChange}
            ></NumberInput>
        </Group>
    );
});

export const DropdownSettings = observer(function FormatSettings({
    value,
    onChange,
    options,
    label,
}: {
    label: string;
    value: string | undefined | null;
    onChange: (value: null | string | undefined) => void;
    options: { value: string; label: string }[];
}) {
    return (
        <Group position="apart">
            <SettingsLabel>{label}</SettingsLabel>
            <Select size="xs" sx={{ width: 140 }} clearable onChange={onChange} data={options} value={value}></Select>
        </Group>
    );
});
