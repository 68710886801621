import { Card, Group, Portal, Text, useMantineTheme } from '@mantine/core';
import { ResponsiveLine } from '@nivo/line';
import { useDi } from '@root/Services/DI';
import { FormatService } from '@root/Services/FormatService';
import { addDays, format } from 'date-fns';
import React, { Fragment, MouseEventHandler, useCallback, useEffect, useRef, useState } from 'react';
import { useMemo } from 'react';
import { container, singleton } from 'tsyringe';
import { ChartMargin, useTickSpacing } from './Common';
import { StandardChartProps } from './Models';
import styled from '@emotion/styled';
import { EventEmitter, useEvent } from '@root/Services/EventEmitter';

export function ConfidenceLineChart<T extends Record<string, string | number | Date>>(props: ConfidenceChartProps<T>) {
    const data = useMemo(() => {
        const transformedData = ['P10', 'P50', 'P90'].map((key) => ({
            id: key,
            data: props.data.map((d) => ({ x: d.Date, y: d[key] })),
        }));
        return transformedData;
    }, [props.data]);
    const fmtSvc = useDi(FormatService);
    const confidenceStartDate = props.settings?.confidenceStartDate ?? '';

    const confidenceStartIndex = props.data.findIndex((d) => d.Date > confidenceStartDate);
    const lastSamePointIndex = confidenceStartIndex > 0 ? confidenceStartIndex - 1 : 0;
    const lastSamePointValue = props.data[lastSamePointIndex].Date;
    const tickSpacing = useTickSpacing(3, 3);

    const { combinedData, monthTotals, years, maxDate, maxValue, minValue } = useMemo(() => {
        const dataTraining = data
            .filter((d) => d.id === 'P50')
            .map((d) => ({
                id: 'Cost',
                data: d.data.slice(0, confidenceStartIndex),
            }));
        const dataForecast = data.map((d) => ({
            ...d,
            data: d.data.slice(confidenceStartIndex - 1),
        }));

        let minValue = 0;
        let maxValue = 0;
        const combinedData = [...dataTraining, ...dataForecast];
        const allData = combinedData.flatMap((d) => d.data);
        const dates = allData.map((item) => fmtSvc.parseDateNoTime(item.x as string));
        const monthTotalsLookup = combinedData.reduce((result, item) => {
            for (const pt of item.data as { x: string; y: number }[]) {
                const monthKey = pt.x.toString().substring(0, 7);
                let month = result.get(monthKey);
                if (!month) {
                    result.set(monthKey, (month = { start: pt.x, end: pt.x, total: 0, p10: 0, p90: 0, historical: true }));
                }
                month.end = month.end < pt.x ? pt.x : month.end;
                month.start = month.start > pt.x ? pt.x : month.start;
                if (pt.x >= confidenceStartDate) {
                    month.historical = false;
                    if (item.id === 'P50') {
                        month.total += pt.y as number;
                    } else if (item.id === 'P10') {
                        month.p10 += pt.y as number;
                    } else if (item.id === 'P90') {
                        month.p90 += pt.y as number;
                    }
                } else if (item.id === 'Cost') {
                    month.total += pt.y as number;
                    month.p10 += pt.y as number;
                    month.p90 += pt.y as number;
                }
                minValue = Math.min(minValue, pt.y as number);
                maxValue = Math.max(maxValue, pt.y as number);
            }
            return result;
        }, new Map<string, { start: string; end: string; total: number; p10: number; p90: number; historical: boolean }>());
        const monthTotals = [...monthTotalsLookup.values()].sort((a, b) => (a.start < b.start ? -1 : 1));
        const years = getYearDateRanges(dates);
        const maxDate = dates.reduce((max, d) => (d > max ? d : max), dates[0]);
        return { combinedData, allData, dates, monthTotals, years, maxDate: fmtSvc.toJsonShortDate(maxDate), minValue, maxValue };
    }, [data, confidenceStartIndex]);

    const yFormatter = getYFormatter(maxValue - minValue < 10 ? 'money' : 'money-whole');
    const theme = useMantineTheme();
    const showLines = props.settings?.mode === 'trend-curve' ? false : true;
    const curve = props.settings?.mode === 'trend-curve';
    const extendedData = props.settings?.extendedData;
    const interval = props.settings?.interval ?? 'day';

    const margin = { top: 20, right: extendedData?.length ? 0 : 50, bottom: 70, left: 75 };

    const MonthTotalsComponent = useMemo(
        () => (props: { xScale: any; yScale: any }) => <MonthTotals {...props} totals={monthTotals} minValue={minValue} />,
        [monthTotals, minValue]
    );

    const { onContainerMouseMove, onMouseLeave, sliceTooltip } = useSliceTooltip(
        useCallback((props) => {
            const { points } = props.slice;
            const [point] = points as { data: { x: string; y: number }; serieId: string }[];
            const date = point.data.x as string;
            const id = point.serieId;

            const values = points.reduce((result, item) => {
                const value = item.data.y;
                const key = item.serieId === 'Cost' ? 'p50' : item.serieId.toString().toLowerCase();
                result[key] = value as unknown as number;

                return result;
            }, {} as Record<string, number>) as { p50: number; p10: number; p90: number };
            return <SliceTooltip date={date} interval={interval} historical={id === 'Cost'} {...values} />;
        }, [])
    );
    const allZero = minValue === 0 && maxValue === 0;

    return (
        <SplitContainer points={extendedData?.length} onMouseLeave={onMouseLeave} onMouseMove={onContainerMouseMove}>
            <ChartContainer>
                <ResponsiveLine
                    data={combinedData}
                    margin={margin}
                    useMesh={true}
                    animate
                    colors={(d) => (d.id === 'Cost' ? '#31C2FF' : d.id === 'P50' ? '#FDB022' : '00')}
                    enableSlices={showLines ? 'x' : false}
                    enablePoints={props.settings?.hidePoints ? false : showLines}
                    curve={curve ? 'basis' : undefined}
                    yFormat={yFormatter}
                    yScale={{
                        type: 'linear',
                        stacked: props.settings?.stacked ?? false,
                        min: minValue,
                        max: allZero ? 1 : 'auto',
                    }}
                    areaBlendMode="normal"
                    axisLeft={
                        !showLines
                            ? null
                            : {
                                  renderTick: ({ x, y, textX, textY, rotate, ...props }) => {
                                      return (
                                          <g transform={`translate(${x},${y})`}>
                                              <text
                                                  dominantBaseline={props.textBaseline}
                                                  textAnchor={props.textAnchor}
                                                  transform={`translate(${textX},${textY}) rotate(${rotate})`}
                                                  style={{ fontSize: theme.fontSizes.xs + 'px' }}
                                              >
                                                  {yFormatter(props.value)}
                                              </text>
                                          </g>
                                      );
                                  },
                              }
                    }
                    sliceTooltip={sliceTooltip}
                    axisBottom={{
                        renderTick: ({ x, y, textX, textY, rotate, ...props }) => {
                            const date = fmtSvc.parseDateNoTime(props.value);
                            const isFirstOfWeek = date?.getDay() === 0;
                            const { line: showMinor } = tickSpacing.next(x);

                            return isFirstOfWeek ? (
                                <line x1={x} x2={x} y1={y} y2={y + 7} stroke="#000" strokeWidth={1}></line>
                            ) : showMinor ? (
                                <line x1={x} x2={x} y1={y} y2={y + 5} stroke="#0003" strokeWidth={1}></line>
                            ) : (
                                <></>
                            );
                        },
                    }}
                    layers={[
                        ({ xScale, yScale }) => (
                            <>
                                <rect
                                    shapeRendering="crispEdges"
                                    x={-1}
                                    y={-30}
                                    width={2 + (xScale(maxDate as unknown as number) as number)}
                                    height="110%"
                                    fill="#fff"
                                    stroke="#0003"
                                    strokeWidth={0.5}
                                />
                            </>
                        ),
                        'axes',
                        'areas',
                        // Custom layer to draw confidence area
                        ({ series, lineGenerator, xScale, yScale }) => {
                            const costSeries = series.find((serie) => serie.id === 'Cost');
                            const p90Series = series.find((serie) => serie.id === 'P90');
                            const p50Series = series.find((serie) => serie.id === 'P50');
                            const p10Series = series.find((serie) => serie.id === 'P10');

                            if (!costSeries || !p90Series || !p10Series || !p50Series) {
                                return null;
                            }

                            const costAreaPoints = [
                                { x: xScale(costSeries.data[0].data.x as number), y: yScale(0) },
                                ...costSeries.data.map((point) => ({ x: xScale(point.data.x as number), y: yScale(point.data.y as number) })),
                                { x: xScale(costSeries.data[costSeries.data.length - 1].data.x as number), y: yScale(0) },
                            ];

                            const confidenceAreaLightTop = [
                                ...p50Series.data.map((point, index) => ({
                                    x: xScale(point.data.x as number),
                                    y: yScale(((p90Series.data[index].data.y as number) - (point.data.y as number)) / 2 + (point.data.y as number)),
                                })),
                                ...p90Series.data
                                    .map((point) => ({ x: xScale(point.data.x as number), y: yScale(point.data.y as number) }))
                                    .reverse(),
                            ];

                            const confidenceAreaLightBottom = [
                                ...p50Series.data.map((point, index) => ({
                                    x: xScale(point.data.x as number),
                                    y: yScale(((p10Series.data[index].data.y as number) - (point.data.y as number)) / 2 + (point.data.y as number)),
                                })),
                                ...p10Series.data
                                    .map((point) => ({ x: xScale(point.data.x as number), y: yScale(point.data.y as number) }))
                                    .reverse(),
                            ];

                            const confidenceAreaDark = [
                                ...p50Series.data.map((point, index) => ({
                                    x: xScale(point.data.x as number),
                                    y: yScale(((p90Series.data[index].data.y as number) - (point.data.y as number)) / 2 + (point.data.y as number)),
                                })),
                                ...p50Series.data
                                    .map((point, index) => ({
                                        x: xScale(point.data.x as number),
                                        y: yScale(
                                            ((p10Series.data[index].data.y as number) - (point.data.y as number)) / 2 + (point.data.y as number)
                                        ),
                                    }))
                                    .reverse(),
                            ];

                            const costAreaPath = lineGenerator(costAreaPoints as any) + 'Z';
                            const confidenceAreaPathLightTop = lineGenerator(confidenceAreaLightTop as any) + 'Z';
                            const confidenceAreaPathLightBottom = lineGenerator(confidenceAreaLightBottom as any) + 'Z';
                            const confidenceAreaPathDark = lineGenerator(confidenceAreaDark as any) + 'Z';

                            return (
                                <>
                                    <path d={costAreaPath} fill="#EFFAFF" />
                                    <path d={confidenceAreaPathLightTop} fill="#FEF0C7" />
                                    <path d={confidenceAreaPathLightBottom} fill="#FEF0C7" />
                                    <path d={confidenceAreaPathDark} fill="#FEDF89" />
                                </>
                            );
                        },
                        // Custom layer to draw the vertical line, dividing the training data and forecast data
                        ({ xScale, yScale }) =>
                            lastSamePointIndex === 0 ? null : (
                                <line
                                    x1={xScale(lastSamePointValue as number) as number}
                                    x2={xScale(lastSamePointValue as number) as number}
                                    y1={-20}
                                    y2={yScale(minValue) as number}
                                    stroke="#FDB02280"
                                    strokeWidth={2}
                                />
                            ),
                        'lines',
                        // Custom layer to add horizontal lines for each year
                        ({ xScale, yScale }) => {
                            return <YearLines xScale={xScale as any} yScale={yScale as any} years={years} min={minValue} />;
                        },
                        MonthTotalsComponent,
                        'slices',
                        'mesh',
                        'legends',
                        ({ xScale, yScale }) => (
                            <>
                                <line
                                    shapeRendering="crispEdges"
                                    x1={0}
                                    x2={2 + (xScale(maxDate as unknown as number) as number)}
                                    y1={yScale(0) as number}
                                    y2={yScale(0) as number}
                                    stroke="#0006"
                                    strokeWidth={0.5}
                                />
                            </>
                        ),
                        'crosshair',
                    ]}
                />
            </ChartContainer>
            <ChartTooltipHost />
            {extendedData?.length ? <ExtendedConfidenceBar rawData={extendedData as ConfidenceSerie[]} /> : null}
        </SplitContainer>
    );
}

function getYearDateRanges(dates: Date[]) {
    const yearsLookups = dates.reduce((result, item) => {
        const yearKey = item.getFullYear();
        let year = result.get(yearKey);
        if (!year) {
            result.set(yearKey, (year = { start: item, end: item }));
        }
        year.end = item > year.end ? item : year.end;
        year.start = item < year.start ? item : year.start;
        return result;
    }, new Map<number, { start: Date; end: Date }>());

    return [...yearsLookups.values()].sort((a, b) => (a.start < b.start ? -1 : 1));
}

function MonthTotals({
    totals,
    xScale,
    yScale,
    minValue,
}: {
    xScale: (value: string) => number;
    yScale: (value: number) => number;
    totals: { start: string; end: string; total: number; p10: number; p90: number; historical: boolean }[];
    minValue: number;
}) {
    const fmtSvc = useDi(FormatService);
    const months = useMemo(() => {
        return totals.map((item) => {
            const startX = xScale(item.start) as number;
            const endX = xScale(item.end) as number;
            const narrow = endX - startX < 100;
            const middleX = (startX + endX) / 2;
            const y = yScale(minValue) + 19;
            const from = fmtSvc.parseDateNoTime(item.start);
            const to = fmtSvc.parseDateNoTime(item.end);
            const oneX = (xScale(fmtSvc.toJsonShortDate(addDays(from, 1))) as number) - startX;
            const nextX = endX + oneX;
            const label = format(from, 'MMM');
            const total = fmtSvc.formatMoneyNoDecimals(item.total);
            const skip = endX - startX < 50;
            return { item, startX, endX, nextX, middleX, y, label, total, skip, narrow };
        });
    }, [totals, xScale, yScale]);
    const [showTooltip, hideTooltip] = useChartTooltip();
    return (
        <>
            {months
                .filter((m) => !m.skip)
                .map((m) => (
                    <Fragment key={m.startX}>
                        {m.startX === 0 ? null : (
                            <line
                                x1={m.startX}
                                x2={m.startX}
                                y1={m.y - 8}
                                y2={m.y + 25}
                                shapeRendering="crispEdges"
                                stroke="#0003"
                                strokeWidth={1}
                            ></line>
                        )}

                        <text x={m.middleX} y={m.y} textAnchor="middle" dominantBaseline="middle" fontSize="12px">
                            {m.label}
                        </text>
                        <text x={m.middleX} y={m.y + 16} textAnchor="middle" dominantBaseline="middle" fontSize={m.narrow ? '12px' : '16px'}>
                            {m.total}
                        </text>
                        <HoverBar
                            inactiveColor="#0000"
                            onMouseMove={(evt) => {
                                const widthH = (m.endX - m.startX) / 2;
                                const { x, bottom } = evt.currentTarget.getBoundingClientRect();
                                showTooltip({
                                    renderer: () => (
                                        <SliceTooltip
                                            date={m.item.start}
                                            p10={m.item.p10}
                                            p50={m.item.total}
                                            p90={m.item.p90}
                                            historical={m.item.historical}
                                            interval="month"
                                        />
                                    ),
                                    x: x + widthH,
                                    y: bottom + 10,
                                });
                            }}
                            onMouseLeave={hideTooltip}
                            x={m.startX}
                            y={-20}
                            width={m.nextX - m.startX}
                            height="100%"
                        />
                    </Fragment>
                ))}
        </>
    );
}

function YearLines({
    xScale,
    yScale,
    years,
    min,
}: {
    min: number;
    xScale: (value: string) => number;
    yScale: (value: number) => number;
    years: { start: Date; end: Date }[];
}) {
    const fmtSvc = useDi(FormatService);
    const linePosition = (yScale(min) as number) + 55;
    return (
        <>
            {years.map((year, index) => {
                const start = year.start;
                const end = years.length > index + 1 ? years[index + 1].start : year.end;
                const startX = xScale(fmtSvc.toJsonShortDate(start)) as number;
                const endX = xScale(fmtSvc.toJsonShortDate(end)) as number;
                const middleX = (endX - startX) / 2;

                const yearTextWidth = 20;
                const colors = ['#00A79D', '#5C4B8C', '#2C607B'];
                return endX - startX < 50 ? null : (
                    <React.Fragment key={index}>
                        <line
                            x1={startX + 3}
                            x2={startX + middleX - yearTextWidth}
                            y1={linePosition}
                            y2={linePosition}
                            stroke={colors[index % colors.length]}
                            strokeWidth={2}
                        />
                        <text x={startX + middleX} y={linePosition} textAnchor="middle" dominantBaseline="middle" fontSize="12px">
                            {year.start.getFullYear().toString()}
                        </text>
                        <line
                            x1={startX + middleX + yearTextWidth}
                            x2={endX - 3}
                            y1={linePosition}
                            y2={linePosition}
                            stroke={colors[index % colors.length]}
                            strokeWidth={2}
                        />
                    </React.Fragment>
                );
            })}
        </>
    );
}

function useSliceTooltip(tooltipRenderer: NonNullable<ResponsiveLine['props']['sliceTooltip']>) {
    const { showTooltip, ...rest } = useTooltip();
    const sliceTooltip: NonNullable<ResponsiveLine['props']['sliceTooltip']> = useCallback(
        (props) => {
            const sliceX = props.slice.x0 - props.slice.x;
            showTooltip(() => tooltipRenderer(props), sliceX);
            return <></>;
        },
        [tooltipRenderer]
    );

    return { ...rest, sliceTooltip };
}
function useTooltip() {
    const pos = useRef({ x: 0, y: 0 });
    const [show, close] = useChartTooltip({ anchor: ['center-left', 'center-right'], offsetX: 15 });
    const showTooltip = useCallback((tooltipRenderer: () => React.ReactNode, xOffset?: number, yOffset?: number) => {
        show({ renderer: () => tooltipRenderer(), x: pos.current.x + (xOffset ?? 0), y: pos.current.y + (yOffset ?? 0) });
        return <></>;
    }, []);
    const onContainerMouseMove: MouseEventHandler = useCallback((evt) => {
        pos.current.x = evt.clientX;
        pos.current.y = evt.clientY;
    }, []);

    return { showTooltip, onContainerMouseMove, onMouseLeave: close };
}

function SliceTooltip(props: { interval?: string; date: string; p50: number; p10: number; p90: number; historical?: boolean }) {
    const { historical, p50, p90, p10, interval = 'month', date } = props;
    const fmtSvc = useDi(FormatService);
    const theme = useMantineTheme();
    return (
        <Card shadow="md" px="sm" py="xs" radius="md" sx={{ minWidth: 200, border: `solid 1px #0004` }}>
            <Text size="sm">
                <strong>{interval === 'month' ? 'Month:' : 'Date:'}</strong>{' '}
                {interval === 'month'
                    ? fmtSvc.formatShortMonthYear(fmtSvc.parseDateNoTime(date))
                    : fmtSvc.toShortDate(fmtSvc.parseDateNoTime(date), true)}
            </Text>
            {historical ? null : <TooltipText text="P90" value={p90} />}
            <TooltipText emphasize text={historical ? 'Cost' : 'Median'} value={p50} />
            {historical ? null : <TooltipText text="P10" value={p10} />}
        </Card>
    );
}

function TooltipText({ emphasize, text, value }: { emphasize?: boolean; text: string; value: number }) {
    const fmtSvc = useDi(FormatService);
    const formattedValue = fmtSvc.formatMoneySignificantDecimals(value);
    return (
        <Group position="apart" px="xs" py={3} sx={{ fontWeight: emphasize ? 'bold' : 'normal' }}>
            <Text size={emphasize ? undefined : 'sm'}>{text}</Text>
            <Text size={emphasize ? undefined : 'sm'}>{formattedValue}</Text>
        </Group>
    );
}

interface ConfidenceChartProps<T extends Record<string, any>> extends StandardChartProps<string | number | Date, T> {
    settings?: ConfidenceChartSettings;
}

export interface ConfidenceChartSettings {
    labelAngle?: number;
    margin?: ChartMargin;
    topN?: number;
    stacked?: boolean;
    interval?: string;
    format?: 'percent' | 'int' | 'float' | 'money' | 'money-whole';
    direction?: 'up' | 'down';
    mode?: 'trend-curve' | 'trend-line';
    chartColors?: string[];
    enableArea?: boolean;
    hideXAxis?: boolean;
    hidePoints?: boolean;
    yMax?: number;
    monthTotals?: boolean;
    confidenceStartDate: string;
    extendedData?: ConfidenceSerie[];
}

const SplitContainer = styled.div<{ points?: number }>`
    display: grid;
    grid-template-columns: ${({ points }) => (points ? `1fr ${points * 20 + 70}px` : '1fr')};
    height: 100%;
`;
const ChartContainer = styled.div`
    height: 100%;
    overflow: hidden;
`;

type ConfidenceSerie = { Date: string; P50: number; P10: number; P90: number };
const getBoxPoints = (x1: number, x2: number, y1: number, y2: number) => [
    { x: x1, y: y1 },
    { x: x2, y: y1 },
    { x: x2, y: y2 },
    { x: x1, y: y2 },
];
function ExtendedConfidenceBar({
    rawData,
    segmentW = 18,
    mb = 90,
    mt = 20,
}: {
    rawData: ConfidenceSerie[];
    segmentW?: number;
    mb?: number;
    mt?: number;
}) {
    const theme = useMantineTheme();
    const barWidth = segmentW - 2;
    const fmtSvc = useDi(FormatService);
    type SeriePoint = { x: string; y: number } & ConfidenceSerie;
    const barWidthH = barWidth / 2;
    const { min, max, data, maxX, xValues, dates } = useMemo(() => {
        const xValues: string[] = [];
        const { dates, points, ...results } = rawData.reduce(
            (result, item) => {
                const date = fmtSvc.parseDateNoTime(item.Date);

                xValues.push(fmtSvc.toJsonShortDate(date));
                result.dates.push(date);
                result.points.push({ ...item, x: item.Date, y: item.P50 });
                result.maxDt = date > result.maxDt ? date : result.maxDt;
                result.minDt = date.getTime() === 0 || date < result.minDt ? date : result.minDt;
                result.min = item.P10 < result.min ? item.P10 : result.min;
                result.max = item.P90 > result.max ? item.P90 : result.max;

                return result;
            },
            { min: 0, max: 0, points: [] as SeriePoint[], dates: [] as Date[], maxDt: new Date(0), minDt: new Date(0) }
        );
        const years = getYearDateRanges(dates);
        const maxX = fmtSvc.toJsonShortDate(results.maxDt);
        return { ...results, years, dates, data: [{ id: 'Extended', data: points }], maxX, xValues };
    }, [rawData]);

    const [showTooltip, hideTooltip] = useChartTooltip({ anchor: 'top-center', offsetY: 10 });
    const onBarHover = (evt: React.MouseEvent<SVGRectElement>, data: SeriePoint) => {
        const { bottom, left, width } = evt.currentTarget.getBoundingClientRect();
        const x = left + width / 2;
        const y = bottom;
        showTooltip({ x, y, renderer: () => <SliceTooltip date={data.Date} p10={data.P10} p50={data.P50} p90={data.P90} /> });
    };
    const tfm = `translate(${segmentW / 2 + 2} 0) scale(${(xValues.length - 1) / xValues.length} 1)`;
    const tfmCss = `translate(${segmentW / 2 + 2}px, 0) scale(${(xValues.length - 1) / xValues.length}, 1)`;
    const allZero = min === 0 && max === 0;

    return (
        <ChartContainer>
            <ResponsiveLine
                enableGridX={false}
                enableGridY={false}
                margin={{ top: 20, right: 70, bottom: 70, left: 0 }}
                axisRight={{
                    tickSize: 0,
                    format: (v) => (max - min > 10 ? fmtSvc.formatMoneyNoDecimals(v as number) : fmtSvc.formatMoney(v as number)),
                }}
                axisLeft={null}
                axisBottom={null}
                data={data}
                key="Date"
                xScale={{ type: 'point' }}
                yScale={{ type: 'linear', min, max: allZero ? 1 : max }}
                enableSlices="x"
                layers={[
                    ({ xScale }) => (
                        <>
                            <rect
                                onMouseLeave={hideTooltip}
                                shapeRendering="crispEdges"
                                x={0}
                                y={-mt - 1}
                                width={2 + (xScale(maxX as unknown as number) as number)}
                                height="110%"
                                fill="#fff"
                                stroke="#0003"
                                strokeWidth={0.5}
                            />
                        </>
                    ),
                    'axes',
                    ({ series, lineGenerator, yScale }) => {
                        const records = series[0].data as unknown as { data: SeriePoint; position: Pt }[];

                        type Pt = { x: number; y: number };
                        type BoxPath = Pt[];
                        const { median, mid, low } = records.reduce(
                            (result, { data, position: { x } }) => {
                                const { mid, median, low } = result;
                                const x1 = x - barWidthH;
                                const x2 = x + barWidthH;

                                const p10 = yScale(data.P10) as number;
                                const p50 = yScale(data.P50) as number;
                                const p90 = yScale(data.P90) as number;

                                const p90diff = p90 - p50;
                                const p10diff = p50 - p10;

                                const p90h = p90diff / 2 + p50;
                                const p10h = p50 - p10diff / 2;

                                median.push([
                                    { x: x1, y: p50 },
                                    { x: x2, y: p50 },
                                ]);
                                mid.push(getBoxPoints(x1, x2, p10h, p90h));
                                low.push(getBoxPoints(x1, x2, p10, p90));

                                return { median, mid, low };
                            },
                            { median: [] as BoxPath[], mid: [] as BoxPath[], low: [] as BoxPath[] }
                        );
                        const createPath = (boxes: BoxPath[], color: string) =>
                            boxes.map((points, i) => {
                                const fill = points.length > 2 ? color : undefined;
                                const stroke = points.length <= 2 ? color : undefined;
                                const d = lineGenerator(points as unknown as BoxPath[]) ?? '';
                                const key = i;
                                return <path {...{ fill, stroke, d, key }} />;
                            });

                        return (
                            <g transform={tfm}>
                                {createPath(low, '#FEF0C7')}
                                {createPath(mid, '#FEDF89')}
                                {createPath(median, '#FDB022')}
                            </g>
                        );
                    },
                    ({ xScale }) => (
                        <g>
                            <line
                                shapeRendering="crispEdges"
                                x1={0}
                                x2={2 + (xScale(maxX as unknown as number) as number)}
                                y1={0}
                                y2={0}
                                stroke="#0006"
                                style={{ transform: `translate(0, calc(100% - ${mb}px)` }}
                                strokeWidth={0.5}
                            />
                        </g>
                    ),
                    ({ xScale, yScale }) => (
                        <g style={{ transform: `${tfmCss} translate(0, calc(100% - ${mb}px)` }}>
                            {dates
                                .map((date, i) => ({
                                    date,
                                    x: Math.round(xScale(fmtSvc.toJsonShortDate(date) as unknown as number) as number),
                                    y: Math.round(yScale(min) as number),
                                    label: format(date, i === 0 || dates[i - 1]?.getFullYear() !== date.getFullYear() ? `MMM yyyy` : 'MMM'),
                                }))
                                .map(({ x, y, label }, i) => (
                                    <Fragment key={i}>
                                        <line x1={x} x2={x} y1={0} y2={6} stroke={theme.colors.gray[6]} />
                                        <text
                                            dominantBaseline="central"
                                            textAnchor="start"
                                            transform={`translate(${x},${10}) rotate(90.1) scale(0.9)`}
                                            style={{ fontSize: '10pt' }}
                                        >
                                            {label}
                                        </text>
                                    </Fragment>
                                ))}
                        </g>
                    ),
                    ({ xScale, yScale, data }) => {
                        const max = xScale(maxX as unknown as number) as number;
                        const points = data[0].data as SeriePoint[];
                        const barW = max / xValues.length;
                        return (
                            <g transform={`translate(2 -${mt})`}>
                                {points.map((item, i) => (
                                    <HoverBar
                                        onMouseMove={(evt) => onBarHover(evt, item)}
                                        key={i}
                                        x={i * barW}
                                        y={0}
                                        height="100%"
                                        width={segmentW}
                                    />
                                ))}
                            </g>
                        );
                    },
                    'crosshair',
                ]}
            />
        </ChartContainer>
    );
}

const HoverBar = styled.rect<{ inactiveColor?: string }>`
    shape-rendering: crispEdges;
    fill: ${(p) => p.inactiveColor ?? '#00000000'};
    &:hover {
        fill: ${(p) => p.theme.colors.primary[6]}20;
    }
`;

function getYFormatter(format: string): (value: Date | number | string) => string {
    const fmtSvc = container.resolve(FormatService);
    switch (format?.toLowerCase()) {
        case 'percent':
            return (value) => (typeof value === 'number' ? value : 0).toFixed(0) + '%';
        case 'int':
            return (value) => (typeof value === 'number' ? fmtSvc.formatInt(value) : '0');
        case 'money':
            return (value) => (typeof value === 'number' ? fmtSvc.formatMoney(value) : '0.00');
        case 'money-whole':
            return (value) => (typeof value === 'number' ? fmtSvc.formatMoneyNoDecimals(value) : '0');
        case 'bytes':
            return (value) => (typeof value === 'number' ? fmtSvc.formatBytes(value, null) : '0');
        default:
            return (value) => value?.toString();
    }
}

function useChartTooltip(defaults?: Partial<ITooltipRequest>, tooltipKey?: string): [(request: ITooltipRequest) => void, () => void] {
    const tooltipModel = useTooltipModel(tooltipKey);
    const close = useCallback(() => {
        tooltipModel.invalidateTooltip();
    }, []);
    const show = useCallback((request: ITooltipRequest) => {
        tooltipModel.provideTooltip({ ...defaults, ...request });
    }, []);

    return [show, close];
}

type VertAnchor = 'top' | 'center' | 'bottom';
type HorzAnchor = 'left' | 'center' | 'right';
type VhAnchor = `${VertAnchor}-${HorzAnchor}`;
interface ITooltipRequest {
    x: number;
    y: number;
    renderer: (invalidateSize: () => void) => React.ReactNode | null;
    /**
     * Points on the tooltip that should align with the x/y coordinate, defaults to [top, center]
     */
    anchor?: VhAnchor[] | VhAnchor;
    /**
     * Distance from the x coordinate to the tooltip
     */
    offsetX?: number;
    /**
     * Distance from the y coordinate to the tooltip
     */
    offsetY?: number;
}

@singleton()
class ChartTooltipModelFactory {
    private models = new Map<string, { model: ChartTooltipModel; refs: Set<number> }>();
    public get(key?: string) {
        key ??= 'default';
        let result = this.models.get(key);
        if (!result) {
            this.models.set(key, (result = { model: new ChartTooltipModel().init(), refs: new Set<number>() }));
        }
        const refId = result.refs.size;
        result.refs.add(refId);
        const disposer = () => {
            result?.refs.delete(refId);
            if (result?.refs.size === 0) {
                result?.model.dispose();
                this.models.delete(key!);
            }
        };

        return { model: result.model, disposer };
    }
}
class ChartTooltipModel {
    private disposers: (() => void)[] = [];
    private disposed = false;
    private availSpace = { width: 0, height: 0 };
    private requestThrottleMs = 100;
    private throttleReqHandle: number | undefined = undefined;
    private anchorOrigins: { [key in VertAnchor | HorzAnchor]: [number, number] } = {
        top: [0, 1],
        center: [-0.5, -1],
        bottom: [-1, -1],
        left: [0, 1],
        right: [-1, -1],
    };

    public readonly tooltipRequested = new EventEmitter<ITooltipRequest | undefined>(undefined);

    public init() {
        window.addEventListener('resize', this.handleWindowResize);
        this.handleWindowResize();
        this.disposers.push(() => window.removeEventListener('resize', this.handleWindowResize));
        return this;
    }

    public provideTooltip(request: ITooltipRequest) {
        this.emitRequest(request);
    }

    public invalidateTooltip() {
        this.emitRequest(undefined);
    }

    public dispose() {
        clearTimeout(this.throttleReqHandle);
        this.disposers.forEach((d) => d());
        this.disposed = true;
    }

    public reposition(tooltipHost: HTMLDivElement, request: ITooltipRequest) {
        if (this.tooltipRequested.value === request) {
            const { x, y, anchor, offsetX = 0, offsetY = 0 } = this.tooltipRequested.value ?? {};
            const { width: w, height: h } = tooltipHost.getBoundingClientRect();

            return this.getFit(x, y, w, h, anchor, offsetX, offsetY);
        }
    }

    private getFit(x: number, y: number, w: number, h: number, anchor: VhAnchor | VhAnchor[] | undefined, offsetX: number, offsetY: number) {
        const { width, height } = this.availSpace;
        const anchors = anchor && typeof anchor === 'string' ? [anchor] : !anchor || !anchor.length ? ['top-center'] : anchor;
        let result: { orgX: number; orgY: number; x: number; y: number } | undefined = undefined;

        for (const rawAnchor of anchors) {
            const [ancV, ancH] = rawAnchor.split('-') as [VertAnchor, HorzAnchor];
            const [orgX, offsetModX] = this.anchorOrigins[ancH];
            const [orgY, offsetModY] = this.anchorOrigins[ancV];
            const offX = offsetModX * offsetX;
            const offY = offsetModY * offsetY;
            const nextX = x + offX;
            const nextY = y + offY;
            const posL = offX + x + orgX * w;
            const posT = offX + y + orgY * h;
            const posR = posL + w;
            const posB = posT + h;
            const adjX = posL < 0 ? -posL : posR > width ? width - posR : 0;
            const adjY = posT < 0 ? -posT : posB > height ? height - posB : 0;

            if (!adjX && !adjY) {
                return { orgX, orgY, x: nextX, y: nextY };
            } else if (result === undefined) {
                result = { orgX, orgY, x: nextX + adjX, y: nextY + adjY };
            }
        }

        return result;
    }

    private emitRequest(request: ITooltipRequest | undefined) {
        if (!this.disposed) {
            clearTimeout(this.throttleReqHandle);
            this.throttleReqHandle = setTimeout(() => this.tooltipRequested.emit(request), this.requestThrottleMs) as unknown as number;
        }
    }

    private handleWindowResize = () => {
        this.availSpace = { width: window.innerWidth, height: window.innerHeight };
    };
}
function useTooltipModel(key?: string) {
    const factory = useDi(ChartTooltipModelFactory);
    const { model: tooltipModel, disposer } = useMemo(() => factory.get(key), []);

    useEffect(() => disposer, [tooltipModel]);

    return tooltipModel;
}

function ChartTooltipHost({ tooltipKey }: { tooltipKey?: string }) {
    const tooltipModel = useTooltipModel(tooltipKey);
    const [hostEl, setHostEl] = useState<HTMLDivElement | null>(null);
    const [renderer, setRenderer] = useState<{ fn: () => React.ReactNode | null }>();

    const reposition = useCallback((request: ITooltipRequest, hostEl: HTMLDivElement) => {
        if (hostEl) {
            const pos = tooltipModel.reposition(hostEl, request);
            if (pos) {
                const { orgX, orgY, x, y } = pos;
                const transform = `translate(${orgX * 100}%, ${orgY * 100}%) translate(${Math.round(x)}px, ${Math.round(y)}px)`;
                if (hostEl.style.opacity === '0') {
                    hostEl.style.transition = 'none';
                    hostEl.style.transform = transform;
                    hostEl.style.opacity = '1';
                    hostEl.style.transition = 'transform 0.3s';
                } else {
                    hostEl.style.transform = transform;
                }
            }
        }
    }, []);
    const tooltipReqHandler = useCallback(
        (request: ITooltipRequest | undefined) => {
            if (hostEl) {
                if (!request) {
                    hostEl.style.opacity = '0';
                    setRenderer(undefined);
                } else {
                    if (request.renderer) {
                        setRenderer({
                            fn: () => {
                                const result = request.renderer(() => reposition(request, hostEl));
                                setTimeout(() => requestAnimationFrame(() => reposition(request, hostEl)));
                                return result;
                            },
                        });
                    }
                }
            }
        },
        [hostEl]
    );
    useEvent(tooltipModel.tooltipRequested, tooltipReqHandler);

    useEvent(tooltipModel.tooltipRequested);
    return (
        <Portal target={document.body}>
            <ChartTooltipHostEl ref={setHostEl}>{renderer?.fn()}</ChartTooltipHostEl>
        </Portal>
    );
}

const ChartTooltipHostEl = styled.div`
    position: fixed;
    pointer-events: none;
    z-index: 1000;
    transition: transform 0.3s;
    top: 0;
    left: 0;
`;
