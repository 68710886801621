import { ExportRequest } from '@apis/Export/model';
import { AnonymousQueueJobJob } from '@apis/Jobs/model';
import { AnonymousJob } from '@apis/Resources';
import { CheckAwsResources, CheckAwsResourcesJob, IdleResourceActionResult, IdleResourcesStartStopJob, TagResourcesJob } from '@apis/Resources/model';
import { BasicApi } from '@root/Services/BasicApi';
import { FormatService } from '@root/Services/FormatService';
import { JobHierarchyProgress, JobService, StatusInfo } from '@root/Services/Jobs/JobService';
import { NavigationService } from '@root/Services/NavigationService';
import { ComponentType } from 'react';
import {
    IconProps,
    Tags,
    TagsOff,
    Activity,
    CheckupList,
    FileSpreadsheet,
    FileText,
    PlayerPlay,
    PlayerPause,
    ChartArrowsVertical,
} from 'tabler-icons-react';
import { singleton, inject } from 'tsyringe';

export interface JobStatus {
    job: AnonymousJob;
    status: JobHierarchyProgress;
}

const jobTypeNames = new Map<string, string>([
    ['Cloudsaver.Resources.Domain.Aws.Models.CheckAwsResources', 'Check AWS Resources'],
    ['Cloudsaver.Resources.Domain.Models.TagResourcesJob', 'Tag Resources'],
    ['Cloudsaver.Resources.Domain.Aws.Models.CreateEventBridgeConnectionJob', 'Setup Event Bridge'],
    ['Cloudsaver.Customers.Application.Models.ProvisionCompanyJob', 'Provision Company Job'],
    ['Cloudsaver.Export.Application.Models.ExportRequestJob', 'Export Data'],
    ['Cloudsaver.Resources.Domain.Models.IdleResources.IdleResourcesStartStopJob', 'Start/Stop Idle Resources'],
    ['Cloudsaver.InvoiceIngestion.Application.Models.CostForecastRequested', 'Cost Forecast Requested'],
]);

@singleton()
export class ActivityItemAdapter {
    public constructor(
        @inject(JobService) public readonly jobService: JobService,
        @inject(FormatService) public readonly formatSvc: FormatService,
        @inject(BasicApi) public readonly basicApi: BasicApi
    ) {}
    public async loadActivityModel(job: AnonymousJob) {
        const status = await this.jobService.getHierarchyProgress(job.HierarchyId!);
        const jobStatus = {
            job,
            status: status!,
        };
        return { jobStatus, model: this.getActivityModel(jobStatus) };
    }
    public getStatusName(statusInfo: StatusInfo) {
        return statusInfo.inProgress ? 'In progress' : statusInfo.hasErrors ? 'Finished with errors' : 'Finished';
    }
    public getActivityModel(jobStatus: JobStatus) {
        const context = {
            jobService: this.jobService,
            formatter: this.formatSvc,
            jobStatus,
            statusInfo: this.jobService.getStatusInfo(jobStatus.status),
            api: this.basicApi,
        };
        const result = { ...context.statusInfo, ...this.createActivityModel(context) };
        return result;
    }
    public getJobTypes() {
        return jobTypeNames;
    }
    private createActivityModel(context: JobDataAdapterContext) {
        switch (context.jobStatus.job.Type) {
            case 'Cloudsaver.Resources.Domain.Models.TagResourcesJob':
                return getTagJobActivityModel(context);
            case 'Cloudsaver.Resources.Domain.Aws.Models.CheckAwsResources':
                return getAwsSyncActivityModel(context);
            case 'Cloudsaver.Export.Application.Models.ExportRequestJob':
                return getDataExportModel(context);
            case 'Cloudsaver.Resources.Domain.Models.IdleResources.IdleResourcesStartStopJob':
                return getIdleStartStopModel(context);
            case 'Cloudsaver.InvoiceIngestion.Application.Models.CostForecastRequested':
                return getCostForecastModel(context);
            default:
                return getGenericActivityModel(context);
        }
    }
}

export type ActivityItemModel = PartialActivityItemModel & StatusInfo;
interface PartialActivityItemModel {
    title: string;
    description: string;
    icon: ComponentType<IconProps>;
    showProgress: boolean;
    action?: {
        execute: (nav: NavigationService) => Promise<void>;
        description: string;
    };
}
interface JobDataAdapterContext {
    jobStatus: JobStatus;
    formatter: FormatService;
    jobService: JobService;
    statusInfo: StatusInfo;
    api: BasicApi;
}
type IJobDataAdapter = (context: JobDataAdapterContext) => PartialActivityItemModel;

const getTagJobActivityModel: IJobDataAdapter = ({ jobStatus, statusInfo }: JobDataAdapterContext) => {
    const tagData = jobStatus.job.Parameters as TagResourcesJob;
    const { inProgress } = statusInfo;
    const isAdd = tagData.AddTags?.length;
    const isRemove = tagData.DeleteTags?.length;
    const isRename = tagData.Renames?.length || tagData.ReplaceValues;
    const verb =
        isAdd && inProgress
            ? 'Adding'
            : isAdd
            ? 'Added'
            : isRemove && inProgress
            ? 'Removing'
            : isRemove
            ? 'Removed'
            : isRename && inProgress
            ? 'Renaming'
            : isRename
            ? 'Renamed'
            : inProgress
            ? 'Changing'
            : 'Changed';
    const preposition = isRemove ? 'from' : isAdd ? 'to' : 'on';
    const resourceCount = tagData.AllResources ? Infinity : tagData.ResourceIds?.length ?? 1;
    const resourceCountText = resourceCount === Infinity ? 'selected' : resourceCount;
    const tagCount = (tagData.DeleteTags?.length ?? 0) + (tagData.AddTags?.length ?? 0) || 1;
    const pluralRes = resourceCount > 1 ? 's' : '';
    const pluralTag = tagCount > 1 ? 's' : '';

    return {
        title: `${verb} Tag${pluralRes || pluralTag}`,
        description: `${verb} ${tagCount} tag${pluralTag} ${preposition} ${resourceCountText} resource${pluralRes}`,
        icon: isAdd ? Tags : isRemove ? TagsOff : Tags,
        showProgress: resourceCount > 1,
    };
};

const getGenericActivityModel: IJobDataAdapter = ({ jobStatus, formatter, jobService }: JobDataAdapterContext) => {
    const rawName = [...(jobStatus.job.Type?.split('.') ?? [''])].pop();
    const title = formatter.userFriendlyCamelCase(rawName ?? '');
    const statusInfo = jobService.getStatusInfo(jobStatus.status);
    return {
        title,
        description: '',
        icon: Activity,
        showProgress: statusInfo.total > 0,
    };
};

const getAwsSyncActivityModel: IJobDataAdapter = ({ jobStatus, formatter }: JobDataAdapterContext) => {
    const syncData = jobStatus.job.Parameters as CheckAwsResources;
    const title = 'Check AWS Resources';
    const resourceCt = syncData.ResourceIds?.length ?? 0;
    const accounts = syncData.AccountId ? '1 account' : 'all accounts';
    const regions = syncData.Region ? syncData.Region : 'all regions';
    const description = `Sync info for ${resourceCt > 0 ? 'specific' : 'all'} resources from AWS for ${accounts} in ${regions}`;

    return {
        title,
        description,
        icon: CheckupList,
        showProgress: false,
    };
};

const getCostForecastModel: IJobDataAdapter = ({ jobStatus, formatter, statusInfo }: JobDataAdapterContext) => {
    const forecastReq = jobStatus.job.Parameters as { DaysToForecast: number };
    const preparing = statusInfo.inProgress && jobStatus.status.Succeeded === 0;
    const totalInProgress = jobStatus.status.Succeeded + jobStatus.status.Created;
    const inProgress = jobStatus.status.Succeeded > 0 && jobStatus.status.Created > 0 && totalInProgress <= 2;
    const loadingResult = jobStatus.status.Succeeded > 0 && (jobStatus.status.Started > 0 || totalInProgress > 2);
    const failed = statusInfo.hasErrors;
    const ready = !statusInfo.inProgress && !statusInfo.hasErrors;
    const inProgressVerb = preparing ? 'Preparing' : inProgress ? 'Calculating' : loadingResult ? 'Indexing' : 'Running';
    const finishedVerb = failed ? 'Failed' : 'Finished';
    const title = !statusInfo.inProgress ? `Forecast ${finishedVerb}` : `${inProgressVerb} Forecast`;
    const statusDesc = statusInfo.inProgress ? 'in progress' : statusInfo.hasErrors ? 'failed' : 'finished';

    return {
        title,
        description: `${forecastReq.DaysToForecast} day cost forecast ${statusDesc}`,
        icon: ChartArrowsVertical,
        showProgress: false,
        action: failed
            ? undefined
            : {
                  execute: async (nav: NavigationService) => {
                      nav.move('cost-forecasting', { forecastId: jobStatus.job.Id ?? '' });
                  },
                  description: ready ? 'View forecast' : 'Wait for forecast',
              },
    };
};

const getDataExportModel: IJobDataAdapter = ({ jobStatus, formatter, api }: JobDataAdapterContext) => {
    const exportData = jobStatus.job.Parameters as ExportRequest;
    const dataType =
        exportData.Target === 'ResourcesQuery'
            ? 'Resource'
            : exportData.Target === 'RecommendationQuery'
            ? 'Recommendation'
            : exportData.Target === 'DailyInvoiceQuery'
            ? 'Invoice Detail'
            : exportData.Target === 'MonthlyInvoiceQuery'
            ? 'Invoice Summary'
            : exportData.Target === 'CostForecastQuery'
            ? 'Cost Forecast'
            : exportData.Target === 'InvoiceComparisonQuery'
            ? 'Invoice Comparison'
            : exportData.Target === 'TrendAnalysisQuery'
            ? 'Trend Analysis'
            : exportData.Target === 'ComputeMarketShareQuery'
            ? 'Compute Market Share'
            : 'Unknown';
    const failed = jobStatus.job.Status === 'Failed';
    const succeeded = jobStatus.job.Status === 'Succeeded';
    const canceled = jobStatus.job.Status === 'Canceled';
    const inProgress = !(failed || succeeded || canceled);
    const verb = inProgress ? 'Exporting' : 'Exported';
    const title = `${verb} ${dataType}s`;
    const resultDetail = jobStatus.job.ResultDetail as { ContentType?: string };
    const extension = exportData.Type === 'PDF' ? 'pdf' : resultDetail?.ContentType === 'text/csv' ? 'csv' : 'xlsx';
    const recordCount = jobStatus.job.Progress?.Total ? formatter.formatInt(jobStatus.job.Progress?.Total - 1) : '';
    const recordDescription = recordCount ? `${recordCount} ${dataType} records` : `${dataType} data`;
    const hasProgress = jobStatus.job.Progress && jobStatus.job.Progress.Total;

    const statusText =
        inProgress && !recordCount
            ? 'Starting export of'
            : inProgress
            ? 'Exporting'
            : failed
            ? 'Failed export of'
            : succeeded
            ? 'Exported'
            : canceled
            ? 'Canceled export of'
            : 'Exported';
    const description = `${statusText} ${recordDescription} to ${exportData.Type}`;
    return {
        title,
        description,
        icon: exportData.Type === 'Excel' ? FileSpreadsheet : FileText,
        showProgress: !!hasProgress,
        action: !succeeded
            ? undefined
            : {
                  execute: () => {
                      return api.download(
                          `${dataType} - ${recordCount} records.${extension}`,
                          {
                              url: `/Export/Download`,
                              method: 'GET',
                              params: { jobId: jobStatus.job.Id },
                          },
                          'ExportDownload'
                      );
                  },
                  description: 'Finished - download now',
              },
    };
};

export enum IdleActionResultStatuses {
    Success,
    Fail,
    Noop,
}
export type IdleActionResult = {
    Results: (Omit<IdleResourceActionResult, 'Result'> & { Result: IdleActionResultStatuses })[];
};
const getIdleStartStopModel: IJobDataAdapter = ({ jobStatus }: JobDataAdapterContext) => {
    const idleData = jobStatus.job.Parameters as IdleResourcesStartStopJob;
    const resultData = jobStatus.job.ResultDetail as IdleActionResult | undefined;
    const selectedCt = idleData.Resources?.length ?? 0;
    const resultCt = resultData?.Results?.length ?? 0;
    const failCt = resultData?.Results?.filter((x) => x.Result === IdleActionResultStatuses.Fail).length ?? 0;
    const allFailed = jobStatus.job.Status === 'Failed' || resultCt === failCt;
    const inProgress = !['Failed', 'Succeeded', 'Canceled'].includes(jobStatus.job.Status ?? '');
    const verb =
        idleData.ActionType === 'Start' && inProgress
            ? 'Starting'
            : idleData.ActionType === 'Start'
            ? 'Started'
            : inProgress
            ? 'Stopping'
            : 'Stopped';
    const partialFailSuffix = failCt ? `, ${failCt} failed` : '';
    const resourceType = idleData.ResourceType === 'EC2 On Demand' ? 'EC2' : idleData.ResourceType;
    const effectiveCt = inProgress ? selectedCt : resultCt;
    const plural = effectiveCt > 1 ? 's' : '';
    const nonFailCt = effectiveCt - failCt;
    const successPlural = nonFailCt > 1 ? 's' : '';
    const title = inProgress ? `${verb} ${resourceType} Resource${plural}` : `${resourceType} Resource${plural} ${verb}`;
    const failVerb = idleData.ActionType === 'Start' ? 'start' : 'stop';
    const description =
        inProgress || !allFailed
            ? `${verb} ${nonFailCt} ${resourceType} resource${successPlural}${partialFailSuffix}`
            : `Failed to ${failVerb} ${resultCt} ${resourceType}`;
    const icon = idleData.ActionType === 'Start' ? PlayerPlay : PlayerPause;

    return {
        title,
        description,
        icon,
        showProgress: false,
    };
};
