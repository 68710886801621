import { ObjectQueryResult, QuerySelectExpr } from '@apis/Jobs/model';
import { QueryExpr, QueryField, QueryOperation, QueryResult } from '@apis/Resources';
import { IQueryExpr, Query, QuerySortExpr, SchemaField, SchemaType } from '@apis/Resources/model';
import { ReactNode } from 'react';
import { v5 as uuidv5 } from 'uuid';

declare module '@apis/Resources' {
    export interface QueryOperation {
        Operation: string;
        Operands: IQueryExpr[];
    }
    export interface QueryConstant {
        __cast?: 'string';
        Value: any;
    }
    export interface QueryField {
        Field: string;
        Type?: string;
    }
    export type QueryExpr = QueryOperation | QueryConstant | QueryField;

    export interface QueryResult<T> {
        Count?: number | null;
        Results?: T[] | null;
        Types?: SchemaType[] | null;
    }
}

export interface ISchemaProvider {
    getSchema(): Promise<SchemaType[]>;
}

export class SchemaValueProvider {
    public constructor(private readonly schemaSvc: SchemaService, private readonly datasource: (query: Query) => Promise<ObjectQueryResult>) {}
    public getValueProvider = (field: QueryField) => {
        const fieldInfo = this.schemaSvc.getField(field.Field);
        let result: undefined | ((filter: string, max: number) => Promise<any>) = undefined;
        if (fieldInfo && fieldInfo.isPrimitive && fieldInfo.field.TypeName === 'string') {
            result = async (filter: string, max: number = 100) => {
                const qb = queryBuilder<Record<string, string>>();
                if (filter) {
                    qb.where((b) => b.model[fieldInfo.path].contains(filter));
                }
                const queryResult = await qb
                    .take(max)
                    .select((b) => ({
                        value: { Operation: 'values', Operands: [{ Field: fieldInfo.path }, { Value: filter }] } as unknown as string,
                        count: b.count(),
                    }))
                    .execute(this.datasource);

                const comparer = new Intl.Collator(undefined, { sensitivity: 'base' }).compare;
                return queryResult.Results?.map((r) => r.value).sort(comparer);
            };
        }
        return result;
    };
}

export type PrimitiveType = 'string' | 'number' | 'date' | 'boolean' | 'unknown';

export const primitiveIcons: Record<PrimitiveType, string> = {
    string: 'ti ti-alphabet-latin',
    number: 'ti ti-hash',
    date: 'ti ti-calendar-time',
    boolean: 'ti ti-circle-check',
    unknown: 'ti ti-question-mark',
};

export class FieldInfo {
    private _children?: FieldInfo[] | null = null;
    private _typeInfo?: TypeInfo;

    public readonly name: string;
    public readonly isPrimitive: boolean;
    public readonly hasMany: boolean;
    public readonly path: string;
    public readonly pathWithRoot: string;
    public readonly fieldName: string;
    public readonly typeName: string;
    public readonly format?: string;
    public readonly helpText?: string;
    public readonly groupName?: string;
    public constructor(
        public field: SchemaField,
        public schemaSvc: SchemaService,
        public readonly parent: FieldInfo | undefined,
        public owner: TypeInfo
    ) {
        this.fieldName = this.field.Field ?? '';
        this.name = this.field.Name ?? this.field.Field ?? 'Unknown';
        this.isPrimitive = !!this.field.IsPrimitive;
        this.format = (this.field as any).Format;
        this.helpText = this.field.Description ?? undefined;
        this.groupName = this.field.GroupName ?? undefined;
        this.hasMany = !!this.field.HasMany;
        this.typeName = field.TypeName ?? 'unknown';
        this.path = this.parent?.path ? [this.parent?.path, this.field.Field].join('.') : this.field.Field ?? '';
        this.pathWithRoot = [this.parent?.pathWithRoot ?? this.owner.type.TypeId, this.field.Field].join('.');
        this.schemaSvc.registerField(this);
    }
    public get typeInfo() {
        if (this._children === null) {
            this.determineChildren();
        }
        return this._typeInfo;
    }
    public get rootType(): TypeInfo {
        return this.parent ? this.parent.rootType : this.owner;
    }
    public get children() {
        return this._children === null ? this.determineChildren() : this._children;
    }
    public resolveChildren() {
        if (this.children) {
            for (const child of this.children) {
                child.resolveChildren();
            }
        }
        return this._children;
    }
    private determineChildren() {
        let result: FieldInfo[] | undefined = undefined;
        if (!this.isPrimitive) {
            const type = this.schemaSvc.getTypeById(this.field.TypeName ?? '');
            if (type) {
                this._typeInfo = new TypeInfo(type, this.schemaSvc, this);
                result = this._typeInfo?.fields ?? null;
            }
        }
        result?.sort(FieldInfo.sortFields);
        return (this._children = result);
    }
    public getPath() {
        let field: FieldInfo | undefined = this;
        const result: string[] = [];
        while (field) {
            result.unshift(field.fieldName);
            field = field.parent;
        }
        return result;
    }
    public static sortFields = (a: FieldInfo, b: FieldInfo) => {
        if (a.isPrimitive !== b.isPrimitive) {
            return a.isPrimitive ? 1 : -1;
        }
        return a.name.localeCompare(b.name);
    };
    private static formatName(name?: string | null) {
        return name?.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/([A-Z])([A-Z][a-z])/g, '$1 $2');
    }
}
export class TypeInfo {
    public fields: FieldInfo[];
    public readonly name: string;
    public constructor(public readonly type: SchemaType, public readonly schemaSvc: SchemaService, public readonly parent?: FieldInfo) {
        this.fields = type.Fields?.map((f) => new FieldInfo(f, this.schemaSvc, parent, this)) ?? [];
        this.name = type.Name ?? type.TypeId ?? '';
    }
    public get isRoot() {
        return !this.parent;
    }
    public get children() {
        this.fields.sort(FieldInfo.sortFields);
        return this.fields;
    }
}

export class SchemaService {
    private readonly rootTypes: SchemaType[] = [];
    private readonly typeLookup = new Map<string, SchemaType>();
    public readonly fieldLookup = new Map<string, FieldInfo>();
    public readonly types = [] as SchemaType[];
    public rootTypeInfo: TypeInfo[] = [];
    private _childrenResolved = false;
    public constructor(types: SchemaType[]) {
        this.types = types;
        for (const type of types) {
            if (type.IsRoot) {
                this.rootTypes.push(type);
                this.rootTypeInfo.push(new TypeInfo(type, this));
            }
            if (type.TypeId) {
                this.typeLookup.set(type.TypeId, type);
            }
        }
    }

    public resolveChildren() {
        if (!this._childrenResolved) {
            for (const type of this.rootTypeInfo) {
                if (type.fields) {
                    for (const field of type.fields) {
                        field.resolveChildren();
                    }
                }
            }
            this._childrenResolved = true;
        }
    }
    public getRoots() {
        return this.rootTypes.slice();
    }
    public getTypeById(id: string) {
        return this.typeLookup.get(id);
    }
    public registerField(field: FieldInfo) {
        this.fieldLookup.set(this.getFieldLookupKey(field.path, field.rootType.type.TypeId), field);
        this.fieldLookup.set(this.getFieldLookupKey(field.path), field);
    }
    public getField(fieldPath: string, typeId?: string) {
        return this.fieldLookup.get(this.getFieldLookupKey(fieldPath, typeId));
    }
    private getFieldLookupKey(fieldPath: string, typeId?: string | null) {
        return `${typeId ?? '-'}-${fieldPath}`;
    }
    public visitFields(visitor: (field: FieldInfo) => void) {
        const visit = (field: FieldInfo) => {
            visitor(field);
            const children = field.children;
            if (children) {
                for (const child of children) {
                    visit(child);
                }
            }
        };
        for (const root of this.rootTypeInfo) {
            if (root.fields) {
                for (const field of root.fields) {
                    visit(field);
                }
            }
        }
    }
}

export const ValuesGroupOtherText = '__other__';
class FluentOperators {
    public constructor(private parentExpr: QueryExpr = { Value: null }) {}
    public model = new Proxy(
        {},
        {
            get: (target, field) => {
                return new FluentOperators({ Field: field.toString() });
            },
        }
    );
    public get name() {
        return this.parentExpr && 'Field' in this.parentExpr ? this.parentExpr.Field : '';
    }
    public param = (value: any, cast?: 'string') =>
        cast ? new FluentOperators({ __cast: cast, Value: value }) : new FluentOperators({ Value: value });
    public iif = (...exprs: FluentOperators[]) => this.operation('iif', ...exprs.map((e) => this.normalize(e)));
    public eq = (expr: FluentOperators) => this.operation('eq', this.parentExpr, this.normalize(expr));
    public ne = (expr: FluentOperators) => this.operation('ne', this.parentExpr, this.normalize(expr));
    public gt = (expr: FluentOperators) => this.operation('gt', this.parentExpr, this.normalize(expr));
    public gte = (expr: FluentOperators) => this.operation('gte', this.parentExpr, this.normalize(expr));
    public lt = (expr: FluentOperators) => this.operation('lt', this.parentExpr, this.normalize(expr));
    public lte = (expr: FluentOperators) => this.operation('lte', this.parentExpr, this.normalize(expr));
    public after = (expr: FluentOperators) => this.operation('gt', this.parentExpr, this.normalize(expr));
    public onOrAfter = (expr: FluentOperators) => this.operation('gte', this.parentExpr, this.normalize(expr));
    public before = (expr: FluentOperators) => this.operation('lt', this.parentExpr, this.normalize(expr));
    public onOrBefore = (expr: FluentOperators) => this.operation('lte', this.parentExpr, this.normalize(expr));
    public sum = (expr: FluentOperators) => this.operation('sum', this.normalize(expr));
    public avg = (expr: FluentOperators) => this.operation('avg', this.normalize(expr));
    public min = (expr: FluentOperators) => this.operation('min', this.normalize(expr));
    public max = (expr: FluentOperators) => this.operation('max', this.normalize(expr));
    public count = (expr?: FluentOperators) => (expr ? this.operation('count', this.normalize(expr)) : this.operation('count'));
    public countIf = (expr?: FluentOperators) => this.operation('countif', this.normalize(expr));
    public aggIf = (criteria: FluentOperators, aggregation: FluentOperators) =>
        this.operation('aggif', this.normalize(criteria), this.normalize(aggregation));
    public fromExpr = (expr: QueryExpr) => new FluentOperators(expr);
    public values = (field: FluentOperators, filter: string = '', otherValue: undefined | string = ValuesGroupOtherText) =>
        this.operation('values', this.normalize(field), { Value: filter }, { Value: otherValue });
    public coalesce = (...exprs: FluentOperators[]) => this.operation('coalesce', ...exprs.map((e) => this.normalize(e)));
    public countValues = (expr: FluentOperators) => this.operation('countvalues', this.normalize(expr));
    public countUniqueValues = (expr: FluentOperators) => this.operation('countuniquevalues', this.normalize(expr));
    public plus = (expr: FluentOperators) => this.operation('add', this.parentExpr, this.normalize(expr));
    public minus = (expr: FluentOperators) => this.operation('subtract', this.parentExpr, this.normalize(expr));
    public times = (expr: FluentOperators) => this.operation('multiply', this.parentExpr, this.normalize(expr));
    public dividedBy = (expr: FluentOperators) => this.operation('divide', this.parentExpr, this.normalize(expr));
    public mod = (expr: FluentOperators) => this.operation('modulo', this.parentExpr, this.normalize(expr));
    public and = (...exprs: FluentOperators[]) => this.operation('and', ...exprs.map((e) => this.normalize(e)));
    public or = (...exprs: FluentOperators[]) => this.operation('or', ...exprs.map((e) => this.normalize(e)));
    public not = (expr: FluentOperators) => this.operation('not', this.normalize(expr));
    public startsWithX = (expr: FluentOperators) => this.operation('startsWith', this.parentExpr, this.normalize(expr));
    public endsWithX = (expr: FluentOperators) => this.operation('endsWith', this.parentExpr, this.normalize(expr));
    public contains = (expr: FluentOperators) => this.operation('contains', this.parentExpr, this.normalize(expr));
    public notcontains = (expr: FluentOperators) => this.operation('notcontains', this.parentExpr, this.normalize(expr));
    public isNull = () => this.operation('isNull', this.parentExpr);
    public isNotNull = () => this.operation('isNotNull', this.parentExpr);
    public truncDate = (interval: 'hour' | 'day' | 'month', value: Date, tzOffset: number, startDate?: Date, endDate?: Date) =>
        this.operation(
            'truncDate',
            ...[interval, value, tzOffset, startDate ?? null, endDate ?? null].filter((v) => v !== null).map((v) => this.normalize(v))
        );

    private operation(op: string, ...operands: QueryExpr[]) {
        return new FluentOperators({ Operation: op, Operands: operands });
    }

    public resolve(ops?: FluentOperators) {
        return ops ? ops.parentExpr : this.parentExpr;
    }
    private normalize(value: any | FluentOperators) {
        return value instanceof FluentOperators ? value.resolve() : { Value: value };
    }
}

export interface IFluentOperators<TSource> {
    eq(op: TSource | TSource[]): TFluentOps<boolean>;
    ne(op: TSource | TSource[]): TFluentOps<boolean>;
    isNull(): TFluentOps<boolean>;
    isNotNull(): TFluentOps<boolean>;
}
export interface INumericFluentOperators extends IFluentOperators<number> {
    gt(op: number): TFluentOps<boolean>;
    gte(op: number): TFluentOps<boolean>;
    lt(op: number): TFluentOps<boolean>;
    lte(op: number): TFluentOps<boolean>;
    plus(op: number): TFluentOps<number>;
    minus(op: number): TFluentOps<number>;
    timex(op: number): TFluentOps<number>;
    dividedBy(op: number): TFluentOps<number>;
    mod(op: number): TFluentOps<number>;
}
export interface IStringFluentOperators extends IFluentOperators<string> {
    notcontains(op: string): TFluentOps<boolean>;
    contains(op: string): TFluentOps<boolean>;
    startsWithX(op: string): TFluentOps<boolean>;
    endsWithX(op: string): TFluentOps<boolean>;
}
export interface IDateFluentOperators extends IFluentOperators<Date> {
    after(op: Date): TFluentOps<boolean>;
    onOrAfter(op: Date): TFluentOps<boolean>;
    before(op: Date): TFluentOps<boolean>;
    onOrBefore(op: Date): TFluentOps<boolean>;
}

type TFluentOps<T> = Exclude<T, undefined> extends number
    ? INumericFluentOperators
    : Exclude<T, undefined> extends string
    ? IStringFluentOperators
    : Exclude<T, undefined> extends boolean
    ? IFluentOperators<boolean>
    : Exclude<T, undefined> extends Date
    ? IDateFluentOperators
    : IFluentOperators<T>;

export interface IFluentQueryAdapter<T> {
    /**
     * Used to access properties of the type being queried
     *
     * example: q.model.ResourceType.eq('EBS'),
     * aka: ResourceType = 'EBS'
     */
    model: T & { [K in keyof T]: TFluentOps<T[K]> };
    /**
     * Used to create a constant in the query
     *
     * example: q.param(54).gt(q.model.TagCount)
     * aka: 54 > TagCount
     * @param value
     */
    param<V extends number | string | boolean | Date | undefined | null>(value: V, cast?: 'string'): V & TFluentOps<V>;
    iif<V>(expr: TFluentOps<boolean>, trueExpr: TFluentOps<V>, falseExpr: TFluentOps<V>): V & TFluentOps<V>;
    sum(value: number | undefined): TFluentOps<number>;
    avg(value: number | undefined): TFluentOps<number>;
    min(value: number | Date | string | undefined): TFluentOps<typeof value>;
    max(value: number | Date | string | undefined): TFluentOps<typeof value>;
    count(exprs?: TFluentOps<boolean>): TFluentOps<number>;
    countIf(exprs?: TFluentOps<boolean>): TFluentOps<number>;
    coalesce<V>(...values: TFluentOps<V>[]): V & TFluentOps<V>;
    aggIf<T>(criteria: TFluentOps<boolean>, aggregation: TFluentOps<T>): TFluentOps<T>;
    /**
     * Convert an arbitrary expression to incorporate into a query-builder query
     * @param expr
     */
    fromExpr<T>(expr: QueryExpr): TFluentOps<T>;
    /**
     * Get unique values of a field
     * @param expr A field to get the unique values of
     * @param filter A filter which the returned values will match
     * @param otherValue A value to represent the null, defaults to ValuesGroupOtherText which equals '\_\_other\_\_'
     */
    values<V>(expr: TFluentOps<V>, filter?: string, otherValue?: string): TFluentOps<string>;
    countValues<V>(expr: TFluentOps<V>): TFluentOps<number>;
    countUniqueValues<V>(expr: TFluentOps<V>): TFluentOps<number>;
    truncDate(
        interval: 'hour' | 'day' | 'month' | `${number}d`,
        value: Date,
        tzOffset: number,
        startDate?: Date,
        endDate?: Date
    ): TFluentOps<typeof value>;
    and(...exprs: TFluentOps<boolean>[]): TFluentOps<boolean>;
    or(...exprs: TFluentOps<boolean>[]): TFluentOps<boolean>;
    not(expr: TFluentOps<boolean>): TFluentOps<boolean>;
    resolve<T>(builder: TFluentOps<T>): QueryExpr;
}

interface IQueryBuilder<T> {
    build(): Query;
    execute(queryApi: (query: Query) => Promise<ObjectQueryResult>): Promise<QueryResult<T>>;
}

export interface ISearchBuilder<T> extends IQueryBuilder<T> {
    select<TNewType>(
        fields: (exprBuilder: IFluentQueryAdapter<T>) => TNewType
    ): IQueryBuilder<{ [K in keyof TNewType]: Exclude<TNewType[K], undefined> extends TFluentOps<infer V> ? V : TNewType[K] }>;
    where(criteria: ((exprBuilder: IFluentQueryAdapter<T>) => TFluentOps<boolean>) | null): ISearchBuilder<T>;
    skip(value: number): ISearchBuilder<T>;
    take(value: number): ISearchBuilder<T>;
    sortAsc<TValue>(expr: (exprBuilder: IFluentQueryAdapter<T>) => TValue): ISearchBuilder<T>;
    sortDesc<TValue>(expr: (exprBuilder: IFluentQueryAdapter<T>) => TValue): ISearchBuilder<T>;
}

class SearchBuilder {
    private selectExprs: QuerySelectExpr[] = [];
    private criteria?: QueryExpr;
    private sortBy: QuerySortExpr[] = [];
    private _skip?: number;
    private _take?: number;

    public constructor() {}

    public select(expr: (builder: FluentOperators) => Record<string, FluentOperators | any>) {
        const nextType = expr(new FluentOperators());
        const select: QuerySelectExpr[] = [];
        for (const prop in nextType) {
            const rawExpr = nextType[prop];
            const expr = rawExpr instanceof FluentOperators ? rawExpr.resolve() : typeof rawExpr !== 'object' ? { value: rawExpr } : rawExpr;
            select.push({ Alias: prop, Expr: expr });
        }
        this.selectExprs = select;
        return this;
    }
    public where(criteria: (builder: FluentOperators) => FluentOperators) {
        this.criteria = this.normalizeExpr(criteria(new FluentOperators()));
        return this;
    }
    public sortAsc(expr: (builder: FluentOperators) => FluentOperators) {
        return this.sort(false, expr);
    }
    public sortDesc(expr: (builder: FluentOperators) => FluentOperators) {
        return this.sort(true, expr);
    }
    public skip(value: number) {
        this._skip = value;
        return this;
    }
    public take(value: number) {
        this._take = value;
        return this;
    }

    public build() {
        return {
            Select: this.selectExprs,
            Where: this.criteria,
            Sort: this.sortBy,
            Skip: this._skip,
            Take: this._take,
        } as Query;
    }

    public execute(queryApi: (query: Query) => Promise<ObjectQueryResult>) {
        return queryApi(this.build());
    }

    public createExpr = (expr: (builder: FluentOperators) => FluentOperators) => {
        return this.normalizeExpr(expr(new FluentOperators()));
    };

    public createFluentExpr = (expr: (builder: FluentOperators) => FluentOperators) => {
        return expr(new FluentOperators());
    };

    private normalizeExpr(expr: FluentOperators) {
        return expr.resolve();
    }
    private sort(desc: boolean, sort: (builder: FluentOperators) => FluentOperators) {
        const expr = this.normalizeExpr(sort(new FluentOperators()));
        this.sortBy.push({ Direction: desc ? 'Desc' : 'Asc', Expr: expr });
        return this;
    }
}

export function queryBuilder<TModel>() {
    var builder = new SearchBuilder();
    return builder as unknown as ISearchBuilder<TModel>;
}

export function groupExprs<ExprType extends QueryExpr | IQueryExpr>(
    operation: 'and' | 'or',
    operands: undefined | (ExprType | null | undefined)[]
): IQueryExpr | undefined {
    const validOps = !operands ? undefined : operands.length > 0 ? (operands.filter((op) => !!op) as Exclude<ExprType, undefined>[]) : undefined;
    return !validOps ? undefined : validOps.length > 1 ? { Operation: operation, Operands: validOps } : validOps.length > 0 ? validOps[0] : undefined;
}

export function exprBuilder<TModel>() {
    var builder = new SearchBuilder();
    let exprBuilder: FluentOperators;
    builder.createExpr((b) => (exprBuilder = b));
    return { ...builder, builder: exprBuilder! } as unknown as {
        createExpr: (expr: (builder: IFluentQueryAdapter<TModel>) => TFluentOps<any>) => QueryExpr;
        createFluentExpr: <T>(expr: (builder: IFluentQueryAdapter<TModel>) => TFluentOps<T>) => TFluentOps<T> & { resolve: () => QueryExpr };
        builder: IFluentQueryAdapter<TModel>;
    };
}

export function copyExpr(expr: QueryExpr) {
    return JSON.parse(JSON.stringify(expr));
}

export function traverseExpr<T>(expr: QueryExpr | IQueryExpr, evaluator: (expr: QueryExpr) => T | undefined) {
    const stack = [expr];
    while (stack.length) {
        const item = stack.shift();
        if (item) {
            const found = evaluator(item as QueryExpr);
            if (found) {
                return found;
            } else {
                if ('Operands' in item) {
                    for (const operand of item.Operands) {
                        stack.push(operand as QueryExpr);
                    }
                }
            }
        }
    }
    return undefined;
}

export function traverseExprDepthFirst(expr: QueryExpr, evaluator: (expr: QueryExpr, parents: QueryOperation[]) => void) {
    const traverse = (expr: QueryExpr, parents: QueryOperation[]) => {
        if ('Operation' in expr) {
            for (const operand of [...expr.Operands]) {
                traverse(operand as QueryExpr, [...parents, expr]);
            }
        }
        evaluator(expr, parents);
    };
    traverse(expr, []);
}

/**
 * Visit all expressions, replace time with midnight utc
 * @param expr
 * @returns
 */
export function cleanExprDates<T extends QueryExpr | IQueryExpr>(expr: T): T;
export function cleanExprDates<T extends QueryExpr | IQueryExpr>(expr: T[]): T[];
export function cleanExprDates(expr: (undefined | QueryExpr | IQueryExpr) | (undefined | QueryExpr | IQueryExpr)[]) {
    if (!expr) {
        return expr;
    }
    if (expr instanceof Array) {
        return expr.filter((x) => !!x).map((e) => cleanExprDates(e!));
    }
    return traverseExpr(expr, (expr) => {
        if ('Value' in expr && expr.Value instanceof Date) {
            const [date] = expr.Value.toISOString().split('T');
            expr.Value = `${date}T00:00:00.000Z`;
        }
    });
}
/**
 * Visit all expressions in the query, replace time with midnight utc
 * @param query
 * @returns
 */
export function cleanQueryDates(query: Query) {
    cleanExprDates([query.Where, ...(query.Sort?.map((s) => s.Expr) || []), ...(query.Select?.map((s) => s.Expr) || []), query.Having]);
    return query;
}

export function cleanBoolExpr(expr?: QueryExpr | IQueryExpr) {
    if (!expr) {
        return undefined;
    }
    const comparisonOps = ['eq', 'ne', 'gt', 'gte', 'lt', 'lte', 'startswith', 'endswith', 'contains', 'notcontains'];
    const twoOperandOps = new Set(comparisonOps);
    const oneOperandOps = new Set(['isnull', 'isnotnull', 'not']);
    const oneOrMoreOperand = new Set(['and', 'or']);
    const allowArrayOps = new Set(['eq', 'ne']);
    const constantRequiredOps = new Set(comparisonOps);
    const root = { Operation: 'and', Operands: [expr] } as QueryOperation;
    const removeOpnd = (parent?: QueryOperation, op?: QueryExpr) => {
        if (parent) {
            const idx = parent.Operands.indexOf(op as IQueryExpr);
            if (idx >= 0) {
                parent.Operands.splice(idx, 1);
            }
        }
    };

    traverseExprDepthFirst(root, (expr, parents) => {
        const parent = parents[parents.length - 1];
        if ('Operation' in expr) {
            const op = expr.Operation?.toLowerCase();
            const opnds = expr.Operands.length;
            if ((twoOperandOps.has(op) && opnds !== 2) || (oneOperandOps.has(op) && opnds !== 1) || (oneOrMoreOperand.has(op) && opnds < 1)) {
                removeOpnd(parent, expr);
            }
        } else if ('Field' in expr) {
            if (!expr.Field) {
                removeOpnd(parent, expr);
            }
        } else if ('Value' in expr) {
            const parentOp = parents?.[parents.length - 1]?.Operation?.toLowerCase() ?? '';
            if (expr.Value instanceof Array) {
                if (expr.Value.length === 0 || !allowArrayOps.has(parentOp)) {
                    removeOpnd(parent, expr);
                }
            } else if (parentOp && constantRequiredOps.has(parentOp)) {
                if (expr.Value === undefined) {
                    removeOpnd(parent, expr);
                }
            }
        } else {
            removeOpnd(parent, expr);
        }
    });

    return root.Operands[0];
}

/**
 * Deep copy and sort of nested POJOs and arrays. Objects are sorted by property name. Arrays are sorted by JSON stringified values
 * This is not safe for data containing circular references.
 * @param value
 */
export function deepSort<T>(value: T): T {
    if (Array.isArray(value)) {
        return value.map((v) => deepSort(v)).sort((a, b) => (JSON.stringify(a) > JSON.stringify(b) ? 1 : -1)) as unknown as T;
    } else if (typeof value === 'object' && value !== null) {
        const result: Record<string, any> = {};
        Object.keys(value)
            .sort()
            .forEach((key) => {
                result[key] = deepSort((value as Record<string, any>)[key]);
            });
        return result as unknown as T;
    } else {
        return value;
    }
}

function naiveJsonCopy<T>(value: T): T {
    return JSON.parse(JSON.stringify(value));
}

/**
 * Get a guid-like ID for an object
 * The ID will be consistent across calls, even if array elements are reordered or if object keys are reordered
 * @param value
 * @param namespace
 * @returns
 */
export function sortedObjectId(value: unknown, namespace?: string) {
    const sorted = deepSort(naiveJsonCopy(value));
    const uuidNs = !namespace ? '00000000-0000-0000-0000-000000000000' : uuidv5(namespace ?? 'default', '00000000-0000-0000-0000-000000000000');
    return uuidv5(JSON.stringify(sorted), uuidNs);
}
